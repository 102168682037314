<div class="tb-filter-wrapper">
  <button mat-stroked-button class="primary-stroke-button filterBtn" (click)="openDropdown()"
    [ngClass]="{ open: isOpenDropdown }">
    Filter
  </button>
  <div class="custom-filter-sec" *ngIf="isOpenDropdown">
    <h3>
      Filter <button class="filterCloseBtn" (click)="closeDropdown()"></button>
    </h3>
    <form [formGroup]="referrelReportForm" name="doorStepFilterForm" class="form">
      <div class="custom-filter-body" style="justify-content: space-evenly;">
        <div class="custom-filter-col">
          <mat-form-field>
            <input matInput [matDatepicker]="toDatePick" placeholder="From Date" formControlName="fromDate"
              [max]="today">
            <mat-datepicker-toggle matSuffix [for]="toDatePick"></mat-datepicker-toggle>
            <mat-datepicker #toDatePick></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Guru Phone Number</mat-label>
            <input formControlName="guru_number" matInput>
          </mat-form-field>
         

          <mat-form-field appearance="fill">
            <mat-label>Lass Phone Number</mat-label>
            <input formControlName="lassContactNo" matInput>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Lob</mat-label>
            <input formControlName="lob" matInput>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>PPL</mat-label>
            <input formControlName="ppl" matInput>
          </mat-form-field>
          <ng-container *ngIf="role=='SuperAdmin'">
            <label for="region" class="form-label">Select Region <span class="requiredfield">*</span></label>
            <mat-select class="form-control" formControlName="zone" (change)="selectRegion()">
              <mat-option  value="">Please select Region</mat-option >
              <mat-option  [value]="region" *ngFor="let region of regions">
                {{ region }}
              </mat-option >
            </mat-select>
          </ng-container>
          <mat-form-field>
          <mat-label  for="sr" class="form-label">SR Type <span class="requiredfield">*</span></mat-label >
          <mat-select  formControlName="sr" multiple>
            <!-- <mat-option value="">Please select SR type</mat-option> -->
            <mat-option value="First Free Service">First Free Service</mat-option>
            <mat-option value="Second Free Service">Second Free Service</mat-option>
            <mat-option value="Third Free Service">Third Free Service</mat-option>
            <mat-option value="Paid Service">Paid Service</mat-option>
            <mat-option value="Break-down">Break-down</mat-option>
          </mat-select>
        </mat-form-field>
          <mat-form-field>
            <mat-label>Job Card Created Date</mat-label>
            <input matInput [matDatepicker]="jobCardOpeningDate" formControlName="jobCardOpeningDate">
            <mat-datepicker-toggle matSuffix [for]="jobCardOpeningDate"></mat-datepicker-toggle>
            <mat-datepicker #jobCardOpeningDate></mat-datepicker>
          </mat-form-field>
          <mat-checkbox formControlName="starguru" color="primary" (change)="applyFilter()">Star Guru</mat-checkbox>
        </div>
        <div class="custom-filter-col">
          <mat-form-field>
            <input matInput [matDatepicker]="fromDatePick" placeholder="To Date" formControlName="toDate" [max]="today">
            <mat-datepicker-toggle matSuffix [for]="fromDatePick"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePick></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Job Card Id</mat-label>
            <input formControlName="jobcardid" matInput>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Lass Name</mat-label>
            <input formControlName="lassnme" matInput>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Chassis Number</mat-label>
            <input formControlName="chasis_no" matInput>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>PL</mat-label>
            <input formControlName="pl" matInput>
          </mat-form-field>


          <!-- <label for="region" class="form-label">Select Case Type <span class="requiredfield">*</span></label> -->
          <mat-form-field>
            <mat-label label for="region" class="form-label"> Please select case type </mat-label>
            <mat-select formControlName="case_type" (change)="selectRegion()" multiple>
              <!-- <mat-option value="">Please select case type</mat-option> -->
              <mat-option value="Doorstep Booking"> Doorstep Booking </mat-option>

              <mat-option value="Vehicle Breakdown"> Vehicle Breakdown </mat-option>
            </mat-select>
          </mat-form-field>
        <mat-form-field>
          <mat-label for="region" class="form-label">Select State <span class="requiredfield">*</span></mat-label>
          <mat-select formControlName="state" (change)="selectRegion()" multiple>
            <!-- <mat-option value="">Please select State</mat-option> -->
            <mat-option [value]="state.state_name" *ngFor="let state of states">
              {{ state.state_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
          <ng-container *ngIf="role=='SuperAdmin'">
            <mat-form-field appearance="fill">
              <mat-label>Dealer Username</mat-label>
              <input formControlName="dealer" matInput>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="role=='SuperAdmin'">
            <mat-form-field appearance="fill">
              <mat-label>Dealer Name</mat-label>
              <input formControlName="dealer_name" matInput>
            </mat-form-field>
          </ng-container>

        </div>
      </div>
     

      <div class="btn-group filterBtn-group">
        <button mat-stroked-button class="primary-stroke-button" (click)="reset()">Reset</button>
        <button mat-flat-button class="primary-flat-button" (click)="applyFilter()">Apply</button>
      </div>
    </form>
  </div>
</div>

<div class="action-toaster" *ngIf="showToastermessage">
  <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
    {{ showfinalErrorMsg }}
  </div>
</div>