import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from "src/app/services/common.service";
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
@Component({
  selector: 'app-sub-dash-filter',
  templateUrl: './sub-dash-filter.component.html',
  styleUrls: ['./sub-dash-filter.component.scss']
})
export class SubDashFilterComponent implements OnInit {

  @Output() formEventTrigger = new EventEmitter<any>();
  @Input() filterFields: any;
  isOpendropdown: boolean = false;
  subdashfilter: FormGroup;
  filterObj: any;
  showToastermessage: boolean = false;
  showfinalErrorMsg: string = "";
  regions = [
    "East",
    "North",
    "South",
    "West"
  ];
  states: any;
  ProblemData: any;
  emissionormdata: any;
  pldata: any;
  ppldata: any;
  lobData: any;
  sub: Subscription = new Subscription();
  lassName: any;
  starguru:any;
  maxDate: Date = new Date();
  role: any
  constructor(public commonService: CommonService, private fb: FormBuilder, private datePipe: DatePipe,private Dashboard:DashboardService) { }

  ngOnInit(): void {
    this.role = JSON.parse(localStorage.getItem('userDetail')).userdetails.role;
    this.buildFilterForm();
    this.dropdown('lob');
    this.dropdown('ppl');
    this.dropdown('pl');
    this.dropdown('emission_norm');
    this.dropdown('problem_name');
    this.dropdown('state');
    // this.dropdown('sr');
    // this.dropdown('dealer');
  }


  buildFilterForm() {
    this.subdashfilter = this.fb.group({
      fromDate: [''],
      toDate: [''],
      lob:[''],
      ppl:[''],
      pl:[''],
      lass_name:[''],
      lass_number:[''],
      dealer: [''],
      state_name:[''],
      phonenumber:[''],
      emission_norm:[''],
      problem_name:[''],
      starguru: [false],
      sr: [''],
      dealer_name: [''],
    })
  }

  openDropdown() {
    this.isOpendropdown = !this.isOpendropdown;
  }

  closeDropdown() {
    this.isOpendropdown = false;
  }

  reset() {
    this.subdashfilter.reset();
    this.formEventTrigger.emit();
    this.isOpendropdown = false;
  
  }

  sr: any;
  dealer: any;
  stateSelected: boolean = false;
  dropdown(flag) {
   


    //code
    let stateval=this.subdashfilter.controls['state_name']?.value
  let lassval=this.subdashfilter.controls['lass_name']?.value 
  


   
    try {

      let data = {
        "action_type":flag,
        "state_name": [],
        "lass_name":''
      };
      
      if(flag=='lass_name'){
data.state_name=stateval
data.lass_name=lassval
      }

      this.sub.add(this.Dashboard.dropdowndata(data).subscribe({
        next: (res: any) => {
          if (res.success === true) {
            if (flag == 'lob') {
              this.lobData = res.result;
            } else if (flag == 'ppl') {
              this.ppldata = res.result;
            } else if (flag == 'pl') {
              this.pldata = res.result;
            } else if (flag == 'emission_norm') {
              this.emissionormdata = res.result;
            } else if (flag == 'problem_name') {
              this.ProblemData = res.result;
            } else if (flag == 'state') {
              this.states = res.result;
            } else if (flag == 'lass_name') {
              this.lassName = res.result;
            } else if (flag == 'starguru') {
              this.starguru = res.result;
            } else if (flag == 'sr') {
              this.sr = res.result;
            } else if (flag == 'dealer') {
              this.dealer = res.result;
            }
            
            
          } else {
            
            this.lassName = [];
          }
        }
      }));
    } catch (error) {
      console.log(error);
    }
  }
// applyFilter() {
//   const currentDate = new Date();
//   const currentYear = currentDate.getFullYear();
//   const currentMonth = currentDate.getMonth();
//   const twoMonthsAgo = new Date(currentYear, currentMonth - 2, 1);

//   let req = {
//     fromDate: this.datePipe.transform(this.subdashfilter.controls['fromDate'].value || twoMonthsAgo, 'yyyy-MM-dd'),
//     toDate: this.datePipe.transform(this.subdashfilter.controls['toDate'].value || currentDate, 'yyyy-MM-dd'),
//     lass_number: this.subdashfilter.controls['lass_number'].value,
//     lob: this.subdashfilter.controls['lob'].value,
//     ppl: this.subdashfilter.controls['ppl'].value,
//     pl: this.subdashfilter.controls['pl'].value,
//     lass_name: this.subdashfilter.controls['lass_name'].value,
//     state_name: this.subdashfilter.controls['state_name'].value,
//     emission_norm: this.subdashfilter.controls['emission_norm'].value,
//     problem_name: this.subdashfilter.controls['problem_name'].value,
//   };

//   // console.log(this.subdashfilter.controls['fromDate'].value,' =======', this.subdashfilter.controls['toDate'].value,' =======' , this.subdashfilter.controls['zone'].value,'ans ==============',(!this.subdashfilter.controls['fromDate'].value && !this.subdashfilter.controls['toDate'].value && this.subdashfilter.controls['zone'].value));
  
//   let tosterMessage = !this.subdashfilter.controls['fromDate'].value && this.subdashfilter.controls['toDate'].value ? 'Please Enter From Date'
//     : !this.subdashfilter.controls['toDate'].value && this.subdashfilter.controls['fromDate'].value ? 'Please Enter To Date'
//     : (this.subdashfilter.controls['fromDate'].value && this.subdashfilter.controls['toDate'].value) && (new Date(req.fromDate).getTime() > new Date(req.toDate).getTime()) ? 'From date should not be less than To date'
//     : (this.subdashfilter.controls['fromDate'].value && this.subdashfilter.controls['toDate'].value) && !this.isValidDiff() ? 'Please select the date range up to 95days'
//     : (!this.subdashfilter.controls['fromDate'].value && !this.subdashfilter.controls['toDate'].value ) ? 'valid'
//     : (this.subdashfilter.controls['fromDate'].value && this.subdashfilter.controls['toDate'].value ) ? 'valid': (this.subdashfilter.controls['fromDate'].value && this.subdashfilter.controls['toDate'].value);

//   if (tosterMessage == 'valid') {
//     this.formEventTrigger.emit(req);
//     this.isOpendropdown = false;
//   } else {
//     this.showToastermessage = true;
//     this.showfinalErrorMsg = tosterMessage;
//     setTimeout(() => {
//       this.showfinalErrorMsg = "";
//       this.showToastermessage = false;
//     }, 3000);
//   }
// }

updateStarguru() {
  this.starguru = this.subdashfilter.controls['starguru'].value; // Update starguru based on form control value
}

applyFilter() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const threeMonthsAgo = new Date(currentYear, currentMonth - 3, 1);
 
  let req = {
    fromDate: this.datePipe.transform(this.subdashfilter.controls['fromDate'].value || threeMonthsAgo, 'yyyy-MM-dd'),
    toDate: this.datePipe.transform(this.subdashfilter.controls['toDate'].value || currentDate, 'yyyy-MM-dd'),
    lass_number: this.subdashfilter.controls['lass_number'].value,
    dealers: this.subdashfilter.controls['dealer'].value 
      ? this.subdashfilter.controls['dealer'].value.split(',').map(dealer => dealer.trim().toUpperCase()) 
      : [],
    lob: this.subdashfilter.controls['lob'].value,
    dealer_name: this.subdashfilter.controls['dealer_name'].value,
    ppl: this.subdashfilter.controls['ppl'].value,
    pl: this.subdashfilter.controls['pl'].value,
    lass_name: this.subdashfilter.controls['lass_name'].value,
    state_name: this.subdashfilter.controls['state_name'].value,
    emission_norm: this.subdashfilter.controls['emission_norm'].value,
    problem_name: this.subdashfilter.controls['problem_name'].value,
    is_star_guru: this.subdashfilter.controls['starguru'].value,
    service_type: this.subdashfilter.controls['sr'].value,
  };
  // console.log("Dealer username", req.dealers);
  

  let tosterMessage = !this.subdashfilter.controls['fromDate'].value && this.subdashfilter.controls['toDate'].value ? 'Please Enter From Date'
    : !this.subdashfilter.controls['toDate'].value && this.subdashfilter.controls['fromDate'].value ? 'Please Enter To Date'
    : (this.subdashfilter.controls['fromDate'].value && this.subdashfilter.controls['toDate'].value) && (new Date(req.fromDate).getTime() > new Date(req.toDate).getTime()) ? 'From date should not be less than To date'
    : (this.subdashfilter.controls['fromDate'].value && this.subdashfilter.controls['toDate'].value) && !this.isValidDiff() ? 'Please select the date range up to 95 days'
    : (!this.subdashfilter.controls['fromDate'].value && !this.subdashfilter.controls['toDate'].value ) ? 'valid'
    : (this.subdashfilter.controls['fromDate'].value && this.subdashfilter.controls['toDate'].value ) ? 'valid': (this.subdashfilter.controls['fromDate'].value && this.subdashfilter.controls['toDate'].value);

  if (tosterMessage == 'valid') {
    this.formEventTrigger.emit(req);
    this.isOpendropdown = false;
  } else {
    this.showToastermessage = true;
    this.showfinalErrorMsg = tosterMessage;
    setTimeout(() => {
      this.showfinalErrorMsg = "";
      this.showToastermessage = false;
    }, 3000);
  }
}


  isValidDiff() {
    let fromDate = new Date(this.subdashfilter.controls['fromDate']?.value);
    let toDate = new Date(this.subdashfilter.controls['toDate']?.value);
    let diff = Math.floor((Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate()) - Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate())) / (1000 * 60 * 60 * 24))
    return (diff <= 95 && diff >= 0 ? true : false);
  }

}
