<table mat-table matTableExporter 
    class="table full-width-table tb-table" matSort aria-label="Elements" 
    [dataSource]="dataSource">
    <ng-container matColumnDef="id" sticky>
        <th mat-header-cell *matHeaderCellDef class="id-col" style="background-color: white; background: linear-gradient(260.04deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">ID</th>
        <td mat-cell *matCellDef="let element" class="id-col  {{ element.id }} text-nowrap" style="box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        ">
        {{ element.id }}
        </td>
    </ng-container>
    <ng-container matColumnDef="customer_name">
        <th mat-header-cell *matHeaderCellDef class="customer_name-col text-nowrap" style="background-color: white;background: linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
        Customer Name
        </th>
        <td mat-cell *matCellDef="let element" class="customer_name-col text-nowrap">
        {{ element.customer_name}}
        </td>
    </ng-container>
    <ng-container matColumnDef="customer_created_date">
        <th mat-header-cell *matHeaderCellDef class="customer_created_date-col text-nowrap" style=" background-color: white; background: linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
        Customer Created Date
        </th>
        <td mat-cell *matCellDef="let element" class="customer_created_date-col text-nowrap">
        {{ element.created_date?(element.created_date | date: "dd/MM/yyyy" ):'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="customer_phone">
        <th mat-header-cell *matHeaderCellDef class="customer_phone-col text-nowrap" style="background-color: white;background: linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
        Customer Phone Number
        </th>
        <td mat-cell *matCellDef="let element" class="customer_phone-col text-nowrap">
        {{ element.customer_phone}}
        </td>
    </ng-container>
    <ng-container matColumnDef="customer_state">
        <th mat-header-cell *matHeaderCellDef class="customer_state-col text-nowrap" style="background-color: white;background: linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
        Customer State
        </th>
        <td mat-cell *matCellDef="let element" class="customer_state-col text-nowrap">
        {{ element.customer_state }}
        </td>
    </ng-container>
    <ng-container matColumnDef="customer_pincode">
        <th mat-header-cell *matHeaderCellDef class="customer_pincode-col text-nowrap" style="background-color: white;background: linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
        Customer Pincode
        </th>
        <td mat-cell *matCellDef="let element" class="customer_pincode-col text-nowrap">
        {{ element.customer_pincode}}
        </td>
    </ng-container>
    <ng-container matColumnDef="model_intereste">
        <th mat-header-cell *matHeaderCellDef class="model_intereste-col text-nowrap" style="background-color: white;background: linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
        Model Interested
        </th>
        <td mat-cell *matCellDef="let element" class="model_intereste-col text-nowrap">
        {{ element.model_interested}}
        </td>
    </ng-container>
    <ng-container matColumnDef="regulatory_norms">
        <th mat-header-cell *matHeaderCellDef class="regulatory_norms-col text-nowrap" style="background-color: white;background: linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
        Regulatory Norms
        </th>
        <td mat-cell *matCellDef="let element" class="regulatory_norms-col text-nowrap">
        {{ element.regulatory_norms}}
        </td>
    </ng-container>
    <ng-container matColumnDef="customer_registration_number">
        <th mat-header-cell *matHeaderCellDef class="customer_registration_number-col text-nowrap" style="background-color: white;background: linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
            Customer Registration Number
        </th>
        <td mat-cell *matCellDef="let element" class="customer_registration_number-col text-nowrap">
        {{ element.registration_number}}
        </td>
    </ng-container>
    <ng-container matColumnDef="customer_expected_purchase_date">
        <th mat-header-cell *matHeaderCellDef class="customer_expected_purchase_date-col text-nowrap" style="background-color: white;background: linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
            Customer Expected Purchase Date
        </th>
        <td mat-cell *matCellDef="let element" class="customer_expected_purchase_date-col text-nowrap">
        {{ element.expected_purchase_dare ? (element.expected_purchase_dare  | date: "dd/MM/yyyy"):'NA' }}
        </td>
    </ng-container>
    <!-- <ng-container matColumnDef="is_customer_deleted">
        <th mat-header-cell *matHeaderCellDef class="is_customer_deleted-col text-nowrap">
            Customer Deleted
        </th>
        <td mat-cell *matCellDef="let element" class="is_customer_deleted-col text-nowrap">
        {{ element.is_deleted}}
        </td>
    </ng-container> -->
    <!-- <ng-container matColumnDef="guru_id">
        <th mat-header-cell *matHeaderCellDef class="guru_id-col">Guru Id</th>
        <td mat-cell *matCellDef="let element" class="guru_id-col  {{ element.guru?element.guru.id:'' }} text-nowrap">
        {{ element.guru?element.guru.id:'NA' }}
        </td>
    </ng-container> -->
    <ng-container matColumnDef="mechanic_name">
        <th mat-header-cell *matHeaderCellDef class="mechanic_name-col text-nowrap" style="background-color: white;background: linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
        Mechanic Name
        </th>
        <td mat-cell *matCellDef="let element" class="mechanic_name-col text-nowrap">
        {{ element.guru?element.guru.mechanic_name:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="garage_area">
        <th mat-header-cell *matHeaderCellDef class="garage_area-col text-nowrap" style="background-color: white;background: linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
        Garage Area
        </th>
        <td mat-cell *matCellDef="let element" class="garage_area-col text-nowrap">
        {{ element.guru?element.guru.garage_name:'NA' }}
        </td>
    </ng-container>
    <!-- <ng-container matColumnDef="added_by">
        <th mat-header-cell *matHeaderCellDef class="added_by-col text-nowrap">
        Added By
        </th>
        <td mat-cell *matCellDef="let element" class="added_by-col text-nowrap">
        {{ element.guru?element.guru.added_by:'NA' }}
        </td>
    </ng-container> -->
    <!-- <ng-container matColumnDef="approved_by_id">
        <th mat-header-cell *matHeaderCellDef class="approved_by_id-col text-nowrap">
        Approved By ID
        </th>
        <td mat-cell *matCellDef="let element" class="approved_by_id-col text-nowrap">
        {{ element.guru?element.guru.approved_by_id:'NA' }}
        </td>
    </ng-container> -->
    <ng-container matColumnDef="gstin_number">
        <th mat-header-cell *matHeaderCellDef class="gstin_number-col text-nowrap" style="background-color: white;background: linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
        GSTIN Number
        </th>
        <td mat-cell *matCellDef="let element" class="gstin_number-col text-nowrap">
        {{ element.guru?element.guru.gstin_number :'NA' }}
        </td>
    </ng-container>
    <!-- <ng-container matColumnDef="msr_contact">
        <th mat-header-cell *matHeaderCellDef class="msr_contact-col text-nowrap">
        MSR Contact
        </th>
        <td mat-cell *matCellDef="let element" class="msr_contact-col text-nowrap">
        {{ element.guru?element.guru.msr_contact:'NA' }}
        </td>
    </ng-container> -->
    <!-- <ng-container matColumnDef="loyality_points">
        <th mat-header-cell *matHeaderCellDef class="loyality_points-col text-nowrap">
        Loyalty Points
        </th>
        <td mat-cell *matCellDef="let element" class="loyality_points-col text-nowrap">
            {{ element.guru?element.guru.loyalty_points:'NA' }}
        </td>
    </ng-container> -->
    <ng-container matColumnDef="upi_id">
        <th mat-header-cell *matHeaderCellDef class="upi_id-col text-nowrap" style="background-color: white;background:linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
        UPI ID
        </th>
        <td mat-cell *matCellDef="let element" class="upi_id-col text-nowrap">
            {{ element.guru?element.guru.upi_id:'NA' }}
        </td>
    </ng-container>
    <!-- <ng-container matColumnDef="last_invoice_number">
        <th mat-header-cell *matHeaderCellDef class="last_invoice_number-col text-nowrap">
        Last Invoice Number
        </th>
        <td mat-cell *matCellDef="let element" class="last_invoice_number-col text-nowrap">
            {{ element.guru?element.guru.last_invoice_number:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="latitude">
        <th mat-header-cell *matHeaderCellDef class="latitude-col text-nowrap">
        Latitude
        </th>
        <td mat-cell *matCellDef="let element" class="latitude-col text-nowrap">
            {{ element.guru?element.guru.latitude:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="longitude">
        <th mat-header-cell *matHeaderCellDef class="longitude-col text-nowrap">
        Longitude
        </th>
        <td mat-cell *matCellDef="let element" class="longitude-col text-nowrap">
            {{ element.guru?element.guru.longitude:'NA' }}
        </td>
    </ng-container> -->
    <!-- <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef class="created_at-col text-nowrap">
        Guru Created At
        </th>
        <td mat-cell *matCellDef="let element" class="created_at-col text-nowrap">
            {{ element.guru?(element.guru.created_date | date: "dd/MM/yyyy") :'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="updated_at">
        <th mat-header-cell *matHeaderCellDef class="updated_at-col text-nowrap">
        Guru Updated At
        </th>
        <td mat-cell *matCellDef="let element" class="updated_at-col text-nowrap">
            {{ element.guru?(element.guru.updated_date | date: "dd/MM/yyyy") :'NA' }}
        </td>
    </ng-container> -->
    <!-- <ng-container matColumnDef="guru_user_id">
        <th mat-header-cell *matHeaderCellDef class="guru_id-col text-nowrap">
        Guru User Id
        </th>
        <td mat-cell *matCellDef="let element" class="guru_id-col text-nowrap">
            {{ element.guru?element.guru.guru_user_data.user_id:'NA' }}
        </td>
    </ng-container> -->
    <ng-container matColumnDef="guru_name">
        <th mat-header-cell *matHeaderCellDef class="guru_name-col text-nowrap" style="background-color: white;background:linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
        Guru Name
        </th>
        <td mat-cell *matCellDef="let element" class="guru_name-col text-nowrap">
            {{ element.guru?(element.guru.guru_user_data.first_name+' '+element.guru.guru_user_data.last_name ) :'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="guru_username">
        <th mat-header-cell *matHeaderCellDef class="guru_username-col text-nowrap" style="background-color: white;background:linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
        Guru Phone Number
        </th>
        <td mat-cell *matCellDef="let element" class="guru_username-col text-nowrap">
            {{ element.guru?element.guru.guru_user_data.username:'NA' }}
        </td>
    </ng-container>

    <ng-container matColumnDef="guru_state">
        <th mat-header-cell *matHeaderCellDef class="guru_state-col text-nowrap" style="background-color: white;background: linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
        Guru State
        </th>
        <td mat-cell *matCellDef="let element" class="guru_state-col text-nowrap">
            {{ element.guru?element.guru.state_name:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="guru_region">
        <th mat-header-cell *matHeaderCellDef class="guru_region-col text-nowrap" style="background-color: white;background:linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
        Guru Region
        </th>
        <td mat-cell *matCellDef="let element" class="guru_region-col text-nowrap">
            {{ element.guru?element.guru.zone:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="lass_name">
        <th mat-header-cell *matHeaderCellDef class="lass_name-col text-nowrap" style="background-color: white;background:linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
        Lass Name
        </th>
        <td mat-cell *matCellDef="let element" class="lass_name-col text-nowrap">
            {{ element.guru?element.guru.lass_detail.lass_name:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="lass_number">
        <th mat-header-cell *matHeaderCellDef class="lass_number-col text-nowrap" style="background-color: white;background:linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
        Lass Phone Number
        </th>
        <td mat-cell *matCellDef="let element" class="lass_number-col text-nowrap">
            {{ element.guru?element.guru.lass_detail.lass_number:'NA' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="lass_email">
        <th mat-header-cell *matHeaderCellDef class="lass_email-col text-nowrap" style="background-color: white;background: linear-gradient(165deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%) !important;">
        Lass Email
        </th>
        <td mat-cell *matCellDef="let element" class="lass_email-col text-nowrap">
            {{ element.guru?element.guru.lass_detail.lass_email:'NA' }}
        </td>
    </ng-container>

    <tr class="tb-head-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr class="tb-item-row" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>