import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from 'src/app/services/dashboard.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-star-guru-details-dialog',
  templateUrl: './star-guru-details-dialog.component.html',
  styleUrls: ['./star-guru-details-dialog.component.scss']
})
export class StarGuruDetailsDialogComponent {
  // service: string;
  // dealer: string;
  // pincode: string;
  // state: string;
  // district: string;
  // city: string;
  // servicedealer: string;
  // servicedealerdivision: string;
  // salesedealer: string;
  // salesdealerdivision: string;
  pincodeBasedAllLocationsData: any;
  states:any = [];
	districts:any = [];
	talukas:any = [];
	cities:any = [];
	dealers:any = [];
	dealerDivisions:any = [];

  servicedealerDivisions:any = [];
	distributors:any = [];
	distributorDivisions:any = [];
    servicedealers: any[];
  stateSelected: any;
  selectedServiceDealerName: any;
  account_id: any;
  updatedata: any;
  selectedDealerInfo: any;
  guru_type: any;
  pincode: any;
  // dealerDivisions: any[];
  // dealers: any[];

  constructor(public dialogRef: MatDialogRef<StarGuruDetailsDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private dashboardapi: DashboardService) { 
    this.account_id = data.action_id;
    this.guru_type=data.guru_type
    this.pincode=data.pincode
  }
  starGuruform: FormGroup;

  ngOnInit(): void {
    this.buildFilterForm()
    this.starGuruform.controls.pincode.setValue(this.pincode);
    this.getPincode();
 
  }
  buildFilterForm() {
    this.starGuruform = this.fb.group({
      pincode: ["", [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern(/^-?([0-9]\d*)?$/)]],
      state: ["", [Validators.required]],
      district: ["",],
      city: ["",],
      taluka: ["",],
      dealer: ["", [Validators.required]],
      dealer_division: ["", [Validators.required]],
      servicedealer: ["", [Validators.required]],
      servicedealer_division: ["", [Validators.required]],
      active_user_position_id: ["", [Validators.required]],
			name_s: ["", [Validators.required]],
      service_position_id: ["", [Validators.required]],
			service_name_s: ["", [Validators.required]]
			// active_user_dealer_name: ["", [Validators.required]],

    });
  }


  getPincode(): boolean {
    let pincode = this.starGuruform.controls.pincode.value;
    let requestPayload = { "pincode": pincode, "app_name": "bandhu", "device_id": "24872681273" };
    let isSuccessful = false;

    this.dashboardapi.pinCode(requestPayload).subscribe({
      next: (response) => {
        if (response) {
          this.pincodeBasedAllLocationsData = response;

          for (let x = 0; x < response.length; x++) {
            let loc = response[x];
            if (this.states) {
              let state_codes = this.states.map((sc) => sc["code"]);
              if (!(state_codes && state_codes.includes(loc["state"]["code"]))) {
                this.states.push({ "code": loc["state"]["code"], "name": loc["state"]["name"] });
              }
            } else {
              this.states.push({ "code": loc["state"]["code"], "name": loc["state"]["name"] });
            }
          }
          isSuccessful = true;
        } else {
          console.warn("Pincode not found.");
          isSuccessful = false;
        }
      },
      error: (error) => {
        console.error("API error occurred:", error);
        isSuccessful = false;
      }
    });

    return isSuccessful;
  }
  pincodeChanged(event: any) {
   
    this.starGuruform.updateValueAndValidity();
    this.getPincode();
  }

  getFilteredDistrict(): void {
    let state_selected = this.states.filter((x: any, j: number) => {
      if (this.starGuruform.value.state) {
        if (x["code"] == this.starGuruform.value.state) {
          this.stateSelected = x;
        }
        return (x["code"] == this.starGuruform.value.state);
      } else {
        return true;
      }
    });

    var state_selected_codes = state_selected.map((x: any) => { return x["code"] });
    let filtered_districts = this.pincodeBasedAllLocationsData.filter((item: any, i: number) => {
      return (typeof item["state"] != "undefined" && typeof item["state"]["code"] != "undefined" && state_selected_codes.includes(item["state"]["code"]));
    });

    // this.districts = []; // Clear previous districts
    for (let i = 0; i < filtered_districts.length; i++) {
      const loc = filtered_districts[i];
      if (this.districts && this.districts.length) {
        let district_codes = this.districts.map((x) => x["code"]);
        if (!(district_codes && district_codes.includes(loc["district"]))) {
          this.districts.push({ "code": loc["district"], "name": loc["district"] });
        }
      } else {
        this.districts.push({ "code": loc["district"], "name": loc["district"] });
      }
    }



  
  }


  getFilteredTalukas(): void {
    let district_selected = this.districts.filter((x: any, j: number) => {
      if (this.starGuruform.value.district) {
        return (x["code"] == this.starGuruform.value.district);
      } else {
        return true;
      }
    });

    var district_selected_codes = district_selected.map((x: any) => { return x["code"] });

    let filtered_talukas = this.pincodeBasedAllLocationsData.filter((item: any, i: number) => {
      return (typeof item["district"] != "undefined" && district_selected_codes.includes(item["district"]));
    });

    // this.talukas = []; // Clear previous talukas
    for (let i = 0; i < filtered_talukas.length; i++) {
      const loc = filtered_talukas[i];
      if (this.talukas && this.talukas.length) {
        let code_list = this.talukas.map((x) => x["code"]);
        if (!(code_list && code_list.includes(loc["taluka"]))) {
          this.talukas.push({ "code": loc["taluka"], "name": loc["taluka"], "data": loc });
        }
      } else {
        this.talukas.push({ "code": loc["taluka"], "name": loc["taluka"], "data": loc });
      }
    }



  }

  getFilteredCities(): void {
    let taluka_selected = this.talukas.filter((x: any, j: number) => {
      if (this.starGuruform.value.taluka) {
        console.log('this.starGuruform.value.taluka', this.starGuruform.value.taluka);
        
        return (x["code"] == this.starGuruform.value.taluka);
      } else {
        return true;
      }
    });
    var taluka_selected_codes = taluka_selected.map((x: any) => { return x["code"] });
    let filtered_cities = this.pincodeBasedAllLocationsData.filter((item: any, i: number) => {
      return (typeof item["taluka"] != "undefined" && taluka_selected_codes.includes(item["taluka"]));
    });
    for (let i = 0; i < filtered_cities.length; i++) {
      const loc = filtered_cities[i];
      if (this.cities && this.cities.length) {
        let code_list = this.cities.map((x) => x["code"]);
        if (!(code_list && code_list.includes(loc["city"]))) {
          this.cities.push({ "code": loc["city"], "name": loc["city"] });
        }
      } else {
        this.cities.push({ "code": loc["city"], "name": loc["city"] });
      }
    }
  }

  filterStateDistrictTalukaCity(boolIsStateChanged: boolean = false): any {
    console.log('this.starGuruform.value.filterStateDistrictTalukaCity', this.starGuruform.value.taluka);

    this.getFilteredDistrict();
    this.getFilteredTalukas();
    this.getFilteredCities();


  }

  stateChanged(): void {
    this.cities = [];
    this.talukas = [];
    this.districts = [];
    this.starGuruform.controls.district.reset();
    this.starGuruform.controls.taluka.reset();
    this.starGuruform.controls.city.reset();
    this.filterStateDistrictTalukaCity(false);
    // this.getRegion();
    this.getServiceDealers()
  }
  districtChanged(): void {
    this.cities = [];
    this.talukas = [];
    this.starGuruform.controls.taluka.reset();
    this.starGuruform.controls.city.reset();
    this.filterStateDistrictTalukaCity(false);
    this.getSalesDealers();
    // this.getDistributors();

  }

  talukaChanged(): void {
    console.log('this.starGuruform.value.talukaChanged', this.starGuruform.value.taluka);
    this.cities = [];
    this.starGuruform = null;
    this.starGuruform.controls.city.reset();
    this.filterStateDistrictTalukaCity(false);
    // this.getDistributors();
  }



  getSalesDealers(): void {
    this.dealers = [];
    this.dealerDivisions = [];
    let selectedDistrict = this.starGuruform.controls.district.value;
    if (selectedDistrict) {
      // this.spinnerService.show();
      let requestPayload = { "district": selectedDistrict, offset: 0, limit: 50 };

      this.dashboardapi.salesDealer(requestPayload).subscribe({
        next: (response) => {
          this.dealers = [];
          this.dealerDivisions = [];
          if (response && typeof response["result"] != "undefined") {
            let apiResponseData = Object.values(response["result"]);
            for (let i = 0; i < apiResponseData.length; i++) {
              const dealer = apiResponseData[i];
              this.dealers.push({ "code": dealer["row_id"], "name": dealer["dealer"] });
            }



          } else {
            // this.handleSuccessfulAPIResponse(apiResponse, "'Dealer is not found.");
          }
        }
      })
    }

  }

  totalDivisions: number = 0;
offset: number = 0;
limit: number = 10;
loadingMoreData: boolean = false;
onDropdownScroll(): void {
  const container = document.querySelector('.dropdown-container');
  if (container) {
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const containerHeight = container.clientHeight;

    // If scrolled to 90% of the container height
    if (scrollTop + containerHeight >= scrollHeight * 0.9) {
      const selectedDealer = this.starGuruform.controls.dealer.value;
      if (selectedDealer) {
        this.loadDealerDivisions(selectedDealer,true);
      }
    }
  }
}

onDealerChange(): void {
  const selectedDealer = this.starGuruform.controls.dealer.value;
  if (selectedDealer) {
    // this.resetPagination();
    this.loadDealerDivisions(selectedDealer);
  }
}
  
// loadDealerDivisions(selectedDealer: string): void {
//   if (this.loadingMoreData || (this.offset >= this.totalDivisions && this.totalDivisions > 0)) {
//     return; // Prevent unnecessary API calls
//   }

//   this.loadingMoreData = true;

//   const requestPayload = {
//     offset: this.offset,
//     limit: this.limit,
//     row_id: selectedDealer, // Include the selected dealer's row_id
//   };

//   this.dashboardapi.serviceDealerDivision(requestPayload).subscribe({
//     next: (response: any) => {
//       this.loadingMoreData = false;
//       if (response?.results?.data) {
//         this.dealerDivisions = [...this.dealerDivisions, ...response.results.data];
//         this.totalDivisions = response.results.division_total_count;
//         this.offset += this.limit; // Increment offset for pagination
//       }
//     },
//     error: (err) => {
//       this.loadingMoreData = false;
//       console.error('Error loading divisions:', err);
//     },
//   });
// }
loadDealerDivisions(selectedDealer: string, isNewSelection: boolean = false): void {
  if (isNewSelection) {
    // Reset pagination and data for a new selection
    this.offset = 0;
    this.dealerDivisions = [];
    this.totalDivisions = 0;
  }

  if (this.loadingMoreData) {
    return; // Prevent concurrent API calls
  }

  this.loadingMoreData = true;

  const requestPayload = {
    offset: this.offset,
    limit: this.limit,
    row_id: selectedDealer, // Include the selected dealer's row_id
  };

  this.dashboardapi.dealearDivision(requestPayload).subscribe({
    next: (response: any) => {
      this.loadingMoreData = false;

      if (response?.results?.data?.length > 0) {
        // Append valid data to the existing list
        this.dealerDivisions = [...this.dealerDivisions, ...response.results.data];
        this.totalDivisions = response.results.division_total_count;
        this.offset += this.limit; // Increment offset for pagination

        // Continue fetching next set of data
        this.loadDealerDivisions(selectedDealer);
      } else if (
        response?.division_total_count > 0 &&
        this.offset < response.division_total_count
      ) {
        // No data in the current call but more data is expected
        this.totalDivisions = response.division_total_count; // Update total count
        this.offset += this.limit; // Increment offset for the next call

        // Recursive call to fetch next page
        this.loadDealerDivisions(selectedDealer);
      } else {
        console.warn('No more data available to load.');
      }
    },
    error: (err) => {
      this.loadingMoreData = false;
      console.error('Error loading divisions:', err);
    },
  });
}

getsalesDealerDivisions(): void {
  this.dealerDivisions = [];
  let selectedDealer = this.starGuruform.controls.dealer.value;

  if (selectedDealer) {
    this.selectedDealerInfo = this.dealers.find(dealer => dealer.code === selectedDealer);
    
    // Initialize offset and limit
    let offset = 0;
    const limit = 100;

    const fetchDealerDivisions = (currentOffset: number) => {
      const requestPayload = { row_id: selectedDealer, offset: currentOffset, limit };

      this.dashboardapi.dealearDivision(requestPayload).subscribe({
        next: (response) => {
          if (response && response.results && Array.isArray(response.results.data)) {
            const apiResponseData = response.results.data;

            // Append new data to dealerDivisions
            apiResponseData.forEach(division => {
              this.dealerDivisions.push({
                code: division.ROW_ID,
                name: division.DIV_NAME_s
              });
            });

            console.log('Current dealerDivisions:', this.dealerDivisions);

            // Check if more data needs to be fetched
            const totalDivisions = response.results.division_total_count || 0;
            if (this.dealerDivisions.length < totalDivisions) {
              // Fetch the next set of records
              fetchDealerDivisions(currentOffset + limit);
            }
          } 
          // else {
          //   // confirm(response?.msg);
          
          // }
        },
        error: (err) => {
          console.error("Error fetching dealer divisions:", err);
        }
      });
    };

    // Start fetching divisions
    fetchDealerDivisions(offset);
  }
}






  // getsalesDealerDivisions(): void {
  //   this.dealerDivisions = [];
  //   let selectedDealer = this.starGuruform.controls.dealer.value;
  //   if (selectedDealer) {
  //     // this.spinnerService.show();
  //     this.selectedDealerInfo = this.dealers.find(dealer => dealer.code === selectedDealer);
      
  //     let requestPayload = { "row_id": selectedDealer, offset: 0, limit: 100 };

  //     this.dashboardapi.dealearDivision(requestPayload).subscribe({
  //       next: (response) => {
  //         this.dealerDivisions = [];
  //         if (response && typeof response["results"] != "undefined" && typeof response["results"]["data"] != "undefined") {
  //           let apiResponseData = response["results"]["data"];
  //           for (let i = 0; i < apiResponseData.length; i++) {
  //             const division = apiResponseData[i];
  //             this.dealerDivisions.push({ "code": division["ROW_ID"], "name": division["DIV_NAME_s"] });
  //             console.log('this.dealerDivisions',this.dealerDivisions);
              
  //           }
  //           // this.guruInformationForm.controls.dealer_division.setValue(this.dealerDivisions[0]["code"]);
  //         } else {
  //           confirm(response?.msg);
          
  //         }
  //       }
  //     })

  //   }

  // }

  getUserInfo():void {
    let selectedDealerDivision = this.starGuruform.controls.dealer_division.value;
    if(selectedDealerDivision && selectedDealerDivision.length) {
      // this.spinnerService.show();no
      this.starGuruform.controls.active_user_position_id.setValue("");
      // this.starGuruform.controls.active_user_dealer_code.setValue("");
      // this.starGuruform.controls.active_user_dealer_name.setValue("");
      let requestPayload = { "row_id": selectedDealerDivision };
      
      this.dashboardapi.userInfo(requestPayload).subscribe({
        next:(response)=>{
  
  
          if(response && typeof response["results"]!="undefined" && typeof response["results"][0]!="undefined"){
            let apiResponseData = response["results"][0];
            this.starGuruform.controls.active_user_position_id.setValue(apiResponseData["POSTN_ID_s"]);
          console.log('this.starGuruform.controls.active_user_position_id.value,',this.starGuruform.controls.active_user_position_id.value,)
            this.starGuruform.controls.name_s.setValue(apiResponseData["NAME_s"]);
            // this.starGuruform.controls.active_user_dealer_name.setValue(apiResponseData["DIV_ORG_NAME_s"]);
          } else {
            confirm(response?.msg);
         
          }
  
        }
      })
     
        
       
    }
  }
  

  getServiceDealers(): void {
    this.servicedealers = [];
    this.servicedealerDivisions=[]
    let selectedstate = this.starGuruform.controls.state.value;
    if (selectedstate) {
      // this.spinnerService.show();
      let requestPayload = {
        "state_name": selectedstate,
        "search_filter": "",
        offset: 0,
        limit: 50
      };

      this.dashboardapi.serviceDealer(requestPayload).subscribe({
        next: (response) => {
          if (response && Array.isArray(response.results)) {
            let apiResponseData = response.results;
            for (let i = 0; i < apiResponseData.length; i++) {
              const dealer = apiResponseData[i];
              this.servicedealers.push(dealer);
            }
            
          } else {
            confirm(response?.msg);
            
          }
        }
      })
    }


  }
disablebtn=false
  getServiceDealerDivisions(): void {
    // this.dealerDivisions=[]
    let selectedServiceDealer = this.starGuruform.controls.servicedealer.value;
   

    const selectedDealer = this.servicedealers.find(dealer => dealer.PAR_BU_ID_s === selectedServiceDealer);
    if (selectedDealer) {
      this.selectedServiceDealerName = selectedDealer.ORG_NAME_s; // Store the selected dealer's name
   
    }
    
    if (selectedServiceDealer) {
      // this.spinnerService.show();
      let requestPayload = {
        "PAR_BU_ID_s": selectedServiceDealer,
        offset: 0,
        limit: 100
      };

      this.dashboardapi.serviceDealerDivision(requestPayload).subscribe({
        next: (response) => {
          if (response && Array.isArray(response.msg)) {
            let apiResponseData = response.msg;
            for (let i = 0; i < apiResponseData.length; i++) {
              const division = apiResponseData[i];
              this.servicedealerDivisions.push(division);
            }
          
          } else {
            confirm(response?.msg);
            
          }
          // this.spinnerService.hide();
        }
      })
    }
  }

  getServiceUserInfo():void {
    let selectedserviceDealerDivision = this.starGuruform.controls.servicedealer_division.value;
    if(selectedserviceDealerDivision && selectedserviceDealerDivision.length) {
      // this.spinnerService.show();no
      this.starGuruform.controls.service_position_id.setValue("");
      this.starGuruform.controls.service_name_s.setValue("");
      // this.starGuruform.controls.active_user_dealer_name.setValue("");
      let requestPayload = { "DIV_ID_s": selectedserviceDealerDivision };
      
      this.dashboardapi.serviceUserInfo(requestPayload).subscribe({
        next:(response)=>{
  
  
          if(response && typeof response["msg"]!="undefined" && typeof response["msg"][0]!="undefined"){
            let apiResponseData = response["msg"][0];
            this.disablebtn=true
            this.starGuruform.controls.service_position_id.setValue(apiResponseData["POSTN_ID_s"]);
            this.starGuruform.controls.service_name_s.setValue(apiResponseData["NAME_s"]);
            // this.starGuruform.controls.active_user_dealer_name.setValue(apiResponseData["DIV_ORG_NAME_s"]);
          } else {
            this.disablebtn=true
            confirm(response?.msg);
           
          }
  
        }
      })
     
        
       
    }
  }
  
 

onSave(): void {

  let payload = [{
    dealer_name: this.starGuruform.controls.name_s.value,
    position_id: this.starGuruform.controls.active_user_position_id.value,
    service_position_name: this.starGuruform.controls.service_name_s.value,
    service_position_id: this.starGuruform.controls.service_position_id.value,
    account_type: this.guru_type,
    contact_category: "",
    action_type: "update",
    account_id: this.account_id,
    organization_name: this.selectedServiceDealerName
  }];

  this.dashboardapi.updateApi(payload).subscribe({
    next: (res) => {
      this.updatedata = res;
     
      if (this.updatedata.success==true) {
        // Show success message using SweetAlert2
        Swal.fire({
          title: 'Success!',
          text: this.updatedata.msg,
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          // Close the dialog after the alert is confirmed
          this.dialogRef.close();
          window.location.reload();
        });
      }
      else if(this.updatedata.success==false){
        Swal.fire({
          title: 'Error!',
          text: this.updatedata.msg,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
      else{
        Swal.fire({
          title: 'Error!',
          text: this.updatedata.error.msg.msg,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    },
    error: (err) => {
      // Handle error if needed
      let errorMessage = err.error?.msg?.msg || 'An error occurred. Please try again later.';
     
      
      // Show the error message using SweetAlert2
      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  });

}


// onSave(): void {
//   // Check if the form is valid
//   if (this.starGuruform.valid) {
//     let payload = [{
//       dealer_name: this.starGuruform.controls.name_s.value,
//       position_id: this.starGuruform.controls.active_user_position_id.value,
//       service_position_name: this.starGuruform.controls.service_name_s.value,
//       service_position_id: this.starGuruform.controls.service_position_id.value,
//       account_type: this.guru_type,
//       contact_category: "",
//       action_type: "update",
//       account_id: this.account_id,
//       organization_name: this.selectedServiceDealerName
//     }];

//     this.dashboardapi.updateApi(payload).subscribe({
//       next: (res) => {
//         this.updatedata = res;

//         if (this.updatedata.success) {
//           // Show success message using SweetAlert2
//           Swal.fire({
//             title: 'Success!',
//             text: this.updatedata.msg,
//             icon: 'success',
//             confirmButtonText: 'OK'
//           }).then(() => {
//             // Close the dialog after the alert is confirmed
//             this.dialogRef.close();
//             window.location.reload();
//           });
//         } else if (this.updatedata.success === false) {
//           Swal.fire({
//             title: 'Error!',
//             text: this.updatedata.msg,
//             icon: 'error',
//             confirmButtonText: 'OK'
//           });
//         } else {
//           Swal.fire({
//             title: 'Error!',
//             text: this.updatedata.error.msg.msg,
//             icon: 'error',
//             confirmButtonText: 'OK'
//           });
//         }
//       },
//       error: (err) => {
//         // Handle error if needed
//         let errorMessage = err.error?.msg?.msg || 'An error occurred. Please try again later.';

//         // Show the error message using SweetAlert2
//         Swal.fire({
//           title: 'Error!',
//           text: errorMessage,
//           icon: 'error',
//           confirmButtonText: 'OK'
//         });
//       }
//     });
//   } else {
//     // Form is invalid, display error messages
//     Object.values(this.starGuruform.controls).forEach(control => {
//       if (control.invalid) {
//         control.markAsDirty();
//         control.updateValueAndValidity();
//       }
//     });
//   }
// }
 closeModal() {
   this.onCancel()
 }
 onCancel(): void {
   this.dialogRef.close();
 }


  ngOnDestroy() {
    this.dialogRef.close();
  }
}