import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-sales-dealer',
  templateUrl: './add-sales-dealer.component.html',
  styleUrls: ['./add-sales-dealer.component.scss']
})
export class AddSalesDealerComponent implements OnInit {

  isAddSalesDealer: boolean = false;
  showNodata: boolean = false;
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;
  dataSource = new MatTableDataSource<any>();
  addSalesDealerForm: FormGroup;
  SalesDealerFilterForm: FormGroup;
  submitted: boolean = false;
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pgIndex: number = 0;
  salesdealer: any;
  sub: Subscription = new Subscription();
  isOpenDropdown:boolean = false;
  constructor(private _formBuilder: FormBuilder,private spinner: NgxSpinnerService,private dashboard:DashboardService,) { }
  
  displayedColumns = ["row_id", "Region", "StateOffice", "StateDetail", "District", "Dealer", "OrginaztionType"];

  regions = ["East","North","South","West"];
  states =  [
    {
        "state_name": "Andaman and Nicobar Islands",
        "state_code": "AN"
    },
    {
        "state_name": "Andhra Pradesh",
        "state_code": "AP"
    },
    {
        "state_name": "Assam",
        "state_code": "AS"
    },
    {
        "state_name": "Bihar",
        "state_code": "BR"
    },
    {
        "state_name": "Chhattisgarh",
        "state_code": "CG"
    },
    {
        "state_name": "Chandigarh",
        "state_code": "CH"
    },
    {
        "state_name": "Delhi",
        "state_code": "DL"
    },
    {
        "state_name": "Goa",
        "state_code": "GA"
    },
    {
        "state_name": "Gujarat",
        "state_code": "GJ"
    },
    {
        "state_name": "Himachal Pradesh",
        "state_code": "HP"
    },
    {
        "state_name": "Haryana",
        "state_code": "HR"
    },
    {
        "state_name": "Jharkhand",
        "state_code": "JH"
    },
    {
        "state_name": "Jammu and Kashmir",
        "state_code": "JK"
    },
    {
        "state_name": "Karnataka",
        "state_code": "KA"
    },
    {
        "state_name": "Kerala",
        "state_code": "KL"
    },
    {
        "state_name": "Maharashtra",
        "state_code": "MH"
    },
    {
        "state_name": "Meghalaya",
        "state_code": "ML"
    },
    {
        "state_name": "Manipur",
        "state_code": "MN"
    },
    {
        "state_name": "Madhya Pradesh",
        "state_code": "MP"
    },
    {
        "state_name": "Nagaland",
        "state_code": "NL"
    },
    {
        "state_name": "Odisha",
        "state_code": "OD"
    },
    {
        "state_name": "Punjab",
        "state_code": "PB"
    },
    {
        "state_name": "Puducherry",
        "state_code": "PY"
    },
    {
        "state_name": "Rajasthan",
        "state_code": "RJ"
    },
    {
        "state_name": "Tamil Nadu",
        "state_code": "TN"
    },
    {
        "state_name": "Tripura",
        "state_code": "TR"
    },
    {
        "state_name": "Telangana",
        "state_code": "TS"
    },
    {
        "state_name": "Uttarakhand",
        "state_code": "UK"
    },
    {
        "state_name": "Uttar Pradesh",
        "state_code": "UP"
    },
    {
        "state_name": "West Bengal",
        "state_code": "WB"
    }
]
  ngOnInit(): void {
    this.addSalesDealerForm = this._formBuilder.group({
      rowId: ["",[Validators.required,this.noWhitespaceValidator]],
      region: ["",[Validators.required,this.noWhitespaceValidator]],
      stateOffice: ["",[Validators.required,this.noWhitespaceValidator]],
      stateDetail: ["",[Validators.required,this.noWhitespaceValidator]],
      district: ["",[Validators.required,this.noWhitespaceValidator]],
      dealer: ["",[Validators.required,this.noWhitespaceValidator]],
      orginaztionType: ["",[Validators.required,this.noWhitespaceValidator]],
    });

    this.SalesDealerFilterForm = this._formBuilder.group({
      District: [""],
      Dealer: [""],
      RowID: [""],
      State: [""],
      Region: [""],
    });
    this.salesDealerList();
    // this.getStates();
  }

  // getStates(){
  //   try {
  //     let data = {};
  //     this.sub.add(this.dashboard.optyplMaster(data).subscribe({
  //       next: (res: any) => {
  //         if (res && res.success === true) {
  //           this.states= res.result.state
  //         }else{} 
  //       },
  //       error: (error) => {
  //         console.log(error);
  //       }
  //     }));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  salesDealerList(page: number = 0, pageSize: number = 10) {
    const ListInput: any = {} as any;
    ListInput.action_type = "list";
    ListInput.offset = page;
    ListInput.limit = pageSize;
    ListInput.district =  this.SalesDealerFilterForm.value.District ;
    ListInput.row_id =  this.SalesDealerFilterForm.value.RowID ;
    ListInput.dealer =  this.SalesDealerFilterForm.value.Dealer ;
    ListInput.state =  this.SalesDealerFilterForm.value.State ;
    ListInput.region =  this.SalesDealerFilterForm.value.Region ;
    try {
      this.spinner.show();
      this.sub.add(this.dashboard.salesDealerList(ListInput).subscribe({
        next: (response) => {
          this.spinner.hide();
          if (response?.results) {
            if(response?.results != 'Data not found'){
              this.salesdealer = response?.results;
              this.dataSource = this.salesdealer;  
              this.totalRows = response?.total_count; 
              this.showNodata = false;
            }
            else{
              this.salesdealer = null;
              this.dataSource = null;
              this.showNodata = true;
            }
          } else {
            this.salesdealer = null;
            this.dataSource = null; 
            this.showNodata = true;
          }
        },
        error: (error) => {
          this.spinner.hide();
          throw error;
        }
      }));
    } catch (error) {
      this.spinner.hide();
      throw(error);
      
    }
  }

  addSalesDealerFormData() {
    if (this.addSalesDealerForm.valid) {
      const formData = this.addSalesDealerForm.value;
  
      const requestPayload = {
      row_id: formData.rowId,
        region: formData.region,
        state_office: formData.stateOffice,
        state_detail: formData.stateDetail,
        district: formData.district,
        dealer: formData.dealer,
        org_type: formData.orginaztionType,
        action_type: "add"
      };
  
      this.spinner.show();
      this.dashboard.salesDealerList(requestPayload).subscribe({
        next: (response) => {
          this.spinner.hide();
          if (response?.success==true) {
            this.showfinalSuccessMsg = response.msg;
            this.showToastermessage = true;
            this.addSalesDealerForm.reset();
            // Refresh the list
            this.salesDealerList();
          } else {
            this.showfinalSuccessMsg = "";
            this.showfinalErrorMsg = response.msg;
            this.showToastermessage = true;
          }
          setTimeout(() => {
            this.showToastermessage = false;
          }, 4000);
        },
        error: (error) => {
          this.spinner.hide();
          this.showfinalErrorMsg = "An error occurred";
          this.showToastermessage = true;
        }
      });
    } else {
      this.submitted = true;
    }
  }
  refresh(){
    this.salesDealerList();
    this.SalesDealerFilterForm.reset();
  }
  applyFilter() {
    this.isOpenDropdown = false;
    this.currentPage = 0;
    this.salesDealerList();
  }

  reset(){
    this.SalesDealerFilterForm.reset();
    this.salesDealerList();
    this.closeDropdown();
  }

  openDropdown(){
    this.isOpenDropdown = !this.isOpenDropdown;
  }
  closeDropdown(){
    this.isOpenDropdown = false;
  }

  pageChanged(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.isLoading = true;
    this.salesDealerList(this.currentPage * this.pageSize, this.pageSize);
  }
  
  
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
