import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: 'app-gurulist-filter',
  templateUrl: './gurulist-filter.component.html',
  styleUrls: ['./gurulist-filter.component.scss']
})
export class GurulistFilterComponent implements OnInit {
  @Output() formEventTrigger = new EventEmitter<any>();
  isOpenDropdown:boolean = false;
  guruListFilter: FormGroup;
  filterObj: any;
  showToastermessage:boolean =false;
  showfinalErrorMsg:string="";
  regions = [
    "East",
    "North",
    "South",
    "West"
  ];
  states =  [
    {
        "state_name": "Andaman and Nicobar Islands",
        "state_code": "AN"
    },
    {
        "state_name": "Andhra Pradesh",
        "state_code": "AP"
    },
    {
        "state_name": "Assam",
        "state_code": "AS"
    },
    {
        "state_name": "Bihar",
        "state_code": "BR"
    },
    {
        "state_name": "Chhattisgarh",
        "state_code": "CG"
    },
    {
        "state_name": "Chandigarh",
        "state_code": "CH"
    },
    {
        "state_name": "Delhi",
        "state_code": "DL"
    },
    {
        "state_name": "Goa",
        "state_code": "GA"
    },
    {
        "state_name": "Gujarat",
        "state_code": "GJ"
    },
    {
        "state_name": "Himachal Pradesh",
        "state_code": "HP"
    },
    {
        "state_name": "Haryana",
        "state_code": "HR"
    },
    {
        "state_name": "Jharkhand",
        "state_code": "JH"
    },
    {
        "state_name": "Jammu and Kashmir",
        "state_code": "JK"
    },
    {
        "state_name": "Karnataka",
        "state_code": "KA"
    },
    {
        "state_name": "Kerala",
        "state_code": "KL"
    },
    {
        "state_name": "Maharashtra",
        "state_code": "MH"
    },
    {
        "state_name": "Meghalaya",
        "state_code": "ML"
    },
    {
        "state_name": "Manipur",
        "state_code": "MN"
    },
    {
        "state_name": "Madhya Pradesh",
        "state_code": "MP"
    },
    {
        "state_name": "Nagaland",
        "state_code": "NL"
    },
    {
        "state_name": "Odisha",
        "state_code": "OD"
    },
    {
        "state_name": "Punjab",
        "state_code": "PB"
    },
    {
        "state_name": "Puducherry",
        "state_code": "PY"
    },
    {
        "state_name": "Rajasthan",
        "state_code": "RJ"
    },
    {
        "state_name": "Tamil Nadu",
        "state_code": "TN"
    },
    {
        "state_name": "Tripura",
        "state_code": "TR"
    },
    {
        "state_name": "Telangana",
        "state_code": "TS"
    },
    {
        "state_name": "Uttarakhand",
        "state_code": "UK"
    },
    {
        "state_name": "Uttar Pradesh",
        "state_code": "UP"
    },
    {
        "state_name": "West Bengal",
        "state_code": "WB"
    }
  ]
  role: any;
  constructor(public commonService: CommonService, private fb:FormBuilder ) { 
  }

  ngOnInit(): void {
    this.role = JSON.parse(localStorage.getItem("userDetail")).userdetails.role;
    this.buildFilterForm();
  }

  buildFilterForm(){
    this.guruListFilter = this.fb.group({
      fromDate: [''],
      toDate: [''],
      dealer:[''],
      sso: [''],
      username:[''],
      contact_number: [''],
      lassContactNo:[''],
      lassnme:[''],
      state:[''],
      zone:[''],
      dealer_name: [''],
    });
  }

  openDropdown(){
    this.isOpenDropdown = !this.isOpenDropdown;
  }
  closeDropdown(){
    this.isOpenDropdown = false;
  }

  reset(){
    this.buildFilterForm();
    this.formEventTrigger.emit();
    this.isOpenDropdown = false;
  }
  applyFilter(){
    let fromDate = new Date(this.guruListFilter.controls['fromDate'].value);
    let toDate = new Date(this.guruListFilter.controls['toDate'].value);
    let tosterMessage = !this.guruListFilter.controls['fromDate'].value && this.guruListFilter.controls['toDate'].value ?'Please Enter From Date'
                        :!this.guruListFilter.controls['toDate'].value && this.guruListFilter.controls['fromDate'].value ?'Please Enter To Date'
                        :( this.guruListFilter.controls['fromDate'].value && this.guruListFilter.controls['toDate'].value ) && ( fromDate.getTime() > toDate.getTime() )?'Please enter valid date'
                        :( this.guruListFilter.controls['fromDate'].value && this.guruListFilter.controls['toDate'].value ) && !this.isValidDiff()?'Please select the date range upto 90 days'
                        :'valid';
    if(tosterMessage == 'valid'){
      this.formEventTrigger.emit(this.guruListFilter.value)
      this.isOpenDropdown = false;
    }else{
      this.showToastermessage = true;
      this.showfinalErrorMsg = tosterMessage;
      setTimeout(() => {
        this.showfinalErrorMsg = "";
        this.showToastermessage = false;
      }, 3000);
    }
  }

  isValidDiff(){
    let fromDate = new Date(this.guruListFilter.controls['fromDate'].value);
    let toDate = new Date(this.guruListFilter.controls['toDate'].value);
    let diff = Math.floor((Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate()) - Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()) ) /(1000 * 60 * 60 * 24));
    return (diff <= 90 && diff >= 0 ? true:false);
  }
  
}
