import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { HttpMethod } from "../../constants/http-handlers";
import { MSRListTableItem } from "src/app/models/apm";
import { AppService } from "src/app/services/app.service";
import { CommonService } from "src/app/services/common.service";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../guru-detail/confirm-dialog/confirm-dialog.component";
import { ExcelService } from "src/app/services/excel.service";
import { GurulistFilterComponent } from "./gurulist-filter/gurulist-filter.component";
import { DashboardService } from "src/app/services/dashboard.service";
import Swal from "sweetalert2";
import { Subscription } from "rxjs";
import { EditGuruTypeDialogComponent } from "../edit-guru-type-dialog/edit-guru-type-dialog.component";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: "app-guru-list-table",
  templateUrl: "./guru-list-table.component.html",
  styleUrls: ["./guru-list-table.component.scss"],
  providers: [DatePipe],
})
export class GuruListTableComponent implements AfterViewInit, OnDestroy {
  step: string = "";
  showNodata: boolean = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<MSRListTableItem>;
  dataSource = new MatTableDataSource<MSRListTableItem>();
  sub: Subscription = new Subscription();
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pgIndex: number = 0;
  filterChipList: any[] = [];
  filterObject: any = [];
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    // "id",
    "account_id",
    "guru_name",
    "registration_date",
    "dealer_name",
    "garage_name",
    "garage_area",
    "garage_id",
    "ratings",
    
    "guru_phone_number",
    "jobcard_count",
    "part_fitted",
    "rejected_on",
    "rejection_reason",
    "associated_since",
    // "is_completed",
    "source",
    "dealer",
    "sso",
    "zone",
    "state_name",
    "district",
    "pincode",
    "membership",
    // "is_accepted",
    // "is_personal_details",
    // "id_number",
    // "profile_image",
    "lass_name",
    "lass_number",
    "lass_email",
    "msr_id",
    "msr_name",
    "msr_mobile_num",
    // "guru_type",
    // "star_guru_status",
  ];
  guruList: any = [];
  globalSearchText: string;
  role: string;
  formcontrolValues:any;
  @ViewChild('childComponent', {static: false}) childComponent:GurulistFilterComponent;
  constructor(
    private commonService: CommonService,
    private appService: AppService,
    private excelService:ExcelService,
    private router: Router,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private modalService: BsModalService,
    private dashboard:DashboardService,
  ) {
    if (this.router.isActive) {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.router.onSameUrlNavigation = "reload";
    }
    if (localStorage.getItem("userDetail")) {
      this.role = JSON.parse(localStorage.getItem("userDetail")).userdetails.role;
    }
    this.displayedColumns = this.role === 'RPM' || this.role === 'SuperAdmin' 
  ? [...this.displayedColumns, 'guru_type'] 
  : this.displayedColumns;
  }
  modalRef?: BsModalRef; 
  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    //debugger;
    this.commonService.globalSearchMessage.subscribe(
      (message) => (this.globalSearchText = message)
    );
    this.commonService.globalguruListStatus.subscribe(
      (message) => (this.step = message)
    );
    this.getGurulist();
    
  }
  openEditDialog(row: any): void {
    const dialogRef = this.dialog.open(EditGuruTypeDialogComponent, {
      width: '350px',
      height: '310px', // specify the desired height here
      data: { guru_type: row.guru_type,account_id:row.account_id , pincode: row.pincode },
    });
    // console.log(row.guru_type);
    dialogRef.componentInstance.guruTypeSelected.subscribe((selectedType: string) => {
      // Call updateGuruType with the selected guru type
      this.updateGuruType(row, selectedType);
      this.getGurulist();
    });
  }
  isUpdated = false; // To track if any field is updated

  enableField(fieldName: string): void {
    this.selectedGuru.editFields[fieldName] = true; // Enable the specific field for the selected row
  }
  enableNameEditing(): void {
    this.selectedGuru.editFields.first_name = true; // Enable first name field
    this.selectedGuru.editFields.last_name = true;  // Enable last name field
    this.selectedGuru.isEditingName = true;         // Toggle edit mode
  }

  onFieldEdit(): void {
    this.isUpdated = true; // Enable the Update button if any field is edited
  }

  isEditing: boolean = false; // Flag to enable or disable editing

  enableEditing(): void {
    this.isEditing = true; // Enable all fields for editing
  }
  saveField(fieldName: string): void {
    this.selectedGuru.editFields[fieldName] = false;
    // Logic to save the updated field value (e.g., API call)
    // this.updateGuruInfo();
  }

  async openEditModal(template: TemplateRef<any>, row: any): Promise<void> {
    // Initialize selectedGuru with row data
    this.selectedGuru = { 
      ...row,
      editFields: { 
        first_name: false,
        last_name: false,
        state: false,
        city: false,
        district: false,
        pincode: false,
        account_id: false,
        garage_name: false,
        mobile: false
      }
    };
  
    // Create a promise to handle pincode API call
    const getPincodeData = new Promise((resolve, reject) => {
      if (this.selectedGuru.pincode) {
        const requestPayload = {
          "pincode": this.selectedGuru.pincode,
          "app_name": "bandhu",
          "device_id": "24872681273"
        };
  
        this.dashboard.pinCode(requestPayload).subscribe({
          next: (response) => {
            if (response) {
              this.pincodeBasedAllLocationsData = response;
              resolve(true);
            } else {
              console.warn("Pincode not found.");
              reject("Pincode not found");
            }
          },
          error: (error) => {
            console.error("API error occurred:", error);
            reject(error);
          }
        });
      } else {
        resolve(false);
      }
    });
  
    try {
      // Wait for pincode data
      await getPincodeData;
  
      // Process states
      if (this.pincodeBasedAllLocationsData) {
        this.states = [];
        this.pincodeBasedAllLocationsData.forEach(loc => {
          if (!this.states.some(state => state.code === loc.state.code)) {
            this.states.push({ 
              "code": loc.state.code, 
              "name": loc.state.name 
            });
          }
        });
  
        // Find and set the matching state from pincodeBasedAllLocationsData
        const matchingLocation = this.pincodeBasedAllLocationsData.find(
          loc => loc.district === this.selectedGuru.district
        );
        
        if (matchingLocation) {
          this.selectedGuru.state = matchingLocation.state.code;
        }
      }
  
      // Get filtered districts based on selected state
      this.getFilteredDistrict();
  
      // Get filtered talukas based on selected district
      this.getFilteredTalukas();
  
      // Get filtered cities based on selected taluka
      this.getFilteredCities();
  
      // Show the modal after data is loaded
      this.modalRef = this.modalService.show(template, { 
        class: 'modal-dialog-centered modal-lg', 
        backdrop: 'static' 
      });
  
    } catch (error) {
      console.error("Error loading location data:", error);
      // You might want to show an error message to the user here
    }
  }

  updateGuruType(row: any, newGuruType: string): void {
    const ListInput = [{
      "id": row.id,
      "account_type": newGuruType
    }];

    try {
      this.spinner.show();
      this.sub.add(this.dashboard.starGuruUpdate(ListInput).subscribe({
        next: (response) => {
          this.spinner.hide();
          if (response.success) {
            row.guru_type = newGuruType;
            Swal.fire({
              icon: 'success',
              text: 'The Guru Type has been updated.',
              timer: 2000,
              showConfirmButton: false
            });
          } else {
            this.showToastermessage = true;
            this.showfinalSuccessMsg = "";
            this.showfinalErrorMsg = "Error. Please try again after some time";
            setTimeout(() => {
              this.showToastermessage = false;
            }, 2000);
            
          }
        },
        error: (err) => {
          throw(err);
        }
      }));
    } catch (error) {
      throw(error);
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  getGurulist(formVal?) {
    try {
      const apiUrl = this.appService.guruList;
      const url = `${apiUrl}`;
      let date = new Date();
      let toDate = this.datePipe.transform(formVal ? formVal.toDate : date, "yyyy-MM-dd");
      let fromDate = this.datePipe.transform(formVal ? formVal.fromDate : date.setMonth(date.getMonth() - 3), "yyyy-MM-dd");
  
      let req = {
        "to_date": toDate,
        "from_date": fromDate,
        "lass_name": this.formcontrolValues ? this.formcontrolValues.lassnme : "",
        "lass_number": this.formcontrolValues ? this.formcontrolValues.lassContactNo : "",
        "username": this.formcontrolValues ? this.formcontrolValues.username : "",
        "status": this.step ? this.step : "",
        "contact_number": this.formcontrolValues ? this.formcontrolValues.contact_number : "",
        "region": this.formcontrolValues ? this.formcontrolValues.zone : "",
        "state": this.formcontrolValues ? this.formcontrolValues.state : "",
        "sso": this.formcontrolValues ? this.formcontrolValues.sso : "",
        "dealer": this.formcontrolValues ? this.formcontrolValues.dealer : "",
        "dealer_name": this.formcontrolValues ? this.formcontrolValues.dealer_name : "",
        "offset": this.currentPage === 0 ? 0 : (this.currentPage * 10),
        "limit": 10
      };
  
      const apiObj = {
        requestObj: req,
        url: url,
        methodType: HttpMethod.POST
      };
  
      this.spinner.show();
      this.commonService.commonApiCall(apiObj, (res) => {
        if (res && res.hasOwnProperty("data") && res.data && res.data.length) {
          this.spinner.hide();
          if (res.success === true) {
            this.dataSource = new MatTableDataSource(res.data);
            this.dataSource.sort = this.sort;
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = res.count;
            this.isLoading = false;
            this.showNodata = false;
          } else {
            this.showfinalErrorMsg = res.msg;
            this.showToastermessage = true;
            this.showNodata = true;
          }
        } else {
          this.showNodata = true;
          this.spinner.hide();
          this.showfinalErrorMsg = res.error.msg;
          this.childComponent.closeDropdown();
          this.showToastermessage = true;
        }
      });
    } catch (error) {
      this.spinner.hide();
      this.showfinalErrorMsg = error.error.msg;
      this.showToastermessage = true;
      this.showNodata = true;
      console.error(error);
    }
  }
  
  async downloadGuruListExcel(){
    try{
      this.spinner.show();
      let apiUrl =  this.appService.guruList;
      let date = new Date();
      let toDate = this.datePipe.transform(this.formcontrolValues?this.formcontrolValues.toDate:date, "yyyy-MM-dd");
      let fromDate = this.datePipe.transform(this.formcontrolValues?this.formcontrolValues.fromDate:date.setMonth(date.getMonth() - 3), "yyyy-MM-dd");
      let req = {
        "to_date":toDate?toDate:this.datePipe.transform(date,"yyyy-MM-dd"),
        "from_date":fromDate?fromDate:this.datePipe.transform(date.setMonth(date.getMonth() - 3),"yyyy-MM-dd"),
        "lass_name":this.formcontrolValues?this.formcontrolValues.lassnme:"",
        "lass_number":this.formcontrolValues?this.formcontrolValues.lassContactNo:"",
        "username": this.formcontrolValues?this.formcontrolValues.username:"",
        "status": this.step?this.step:"",
        "contact_number": this.formcontrolValues?this.formcontrolValues.contact_number:"",
        "region": this.formcontrolValues?this.formcontrolValues.zone:"",
        // "registration_date": "2023-01-02",
        "state": this.formcontrolValues?this.formcontrolValues.state:"",
        "sso": this.formcontrolValues?this.formcontrolValues.sso:"",
        "dealer": this.formcontrolValues?this.formcontrolValues.dealer:"",
        "offset": 0,
        "limit": 250,
      }
      let totalResult=0;
      let data:any[] = [];
      let res = await this.commonService.postAsyncRequest(apiUrl,req);
      if(res){
        totalResult = res.count;
        data = res.data;
      }
      let rou = Math.floor(totalResult/250);
      rou = (totalResult%250>0) && (rou > 0)?rou+1:rou; 
      if(rou > 1 ){
        for(var i=1;i<rou;i++){
          req.offset = (i * 250);
          res = await this.commonService.postAsyncRequest(apiUrl,req);
          if(res  && res.hasOwnProperty("data") && res.data && res.data.length ){
            data = [...data, ...res.data];
          }
        }
      }
      let finalData:GuruList[] = [];
      for (let row of data) {
        const ListInput: GuruList = {} as GuruList;
        ListInput.Id =   row.id
        ListInput.Guru_Name = row.guru_name
        ListInput.Registration_Date = row.registration_date?this.datePipe.transform(row.registration_date, "dd-MM-yyyy"):"NA"
        ListInput.Garage_Name = row.garage_name?row.garage_name:"NA"
        ListInput.Garage_Area =   row.garage_area?row.garage_area:"NA"
        ListInput.Garage_Id = row.garage_id?row.garage_id:"NA"
        ListInput.Ratings =  row.ratings?row.ratings:"NA"
        ListInput.Guru_Phone_Number = row.guru_phone_number?row.guru_phone_number:"NA"
        ListInput.Total_Jobcards = row.jobcard_count?row.jobcard_count:"NA"
        ListInput.Total_Part_Fitted = row.part_fitted?row.part_fitted:"NA"
        ListInput.Rejected_On = row.rejected_on?this.datePipe.transform(row.rejected_on, "dd-MM-yyyy"):"NA"
        ListInput.Rejection_Reason = row.rejection_reason?row.rejection_reason:"NA"
        ListInput.Associated_Since = row.associated_since?this.datePipe.transform(row.associated_since, "dd-MM-yyyy"):"NA"
        ListInput.Source = row.source?row.source:"NA"
        ListInput.Dealer = row.dealer ?row.dealer:"NA"
        ListInput.Sso= row.sso?row.sso:"NA"
        ListInput.Zone=row.zone?row.zone:"NA"
        ListInput.State_Name = row.state_name?row.state_name:"NA"
        ListInput.District = row.district?row.district:"NA"
        ListInput.Pincode = row.pincode?row.pincode:"NA"
        ListInput.Membership = row.membership?row.membership:"NA"
        ListInput.Lass_Name = row.lass_details && row.lass_details.lass_name?row.lass_details.lass_name:"NA"
        ListInput.Lass_Number = row.lass_details && row.lass_details.lass_number?row.lass_details.lass_number:"NA"
        ListInput.Lass_Email = row.lass_details && row.lass_details.lass_email?row.lass_details.lass_email:"NA"
        ListInput.Msr_Id= row.msr_details && row.msr_details.msr_id?row.msr_details.msr_id:"NA"
        ListInput.Msr_Name=row.msr_details && row.msr_details.msr_name?row.msr_details.msr_name:"NA"
        ListInput.Msr_Mobile_Number=row.msr_details && row.msr_details.msr_mobile_num?row.msr_details.msr_mobile_num:"NA"
        finalData.push(ListInput);
      }
      if(finalData.length > 0 ){
        this.excelService.exportCancellationAsExcelFile(finalData, 'guru_list_report');
      }else{
        this.showToastermessage = true;
        this.showfinalErrorMsg =
          "No Data For Download";
        setTimeout(() => {
          this.showfinalErrorMsg = "";
          this.showToastermessage = false;
        }, 3000);
      }
      // console.log(finalData);
    }catch(error){
      this.showToastermessage = true;
      this.showfinalSuccessMsg =
        "No Data For Download";
      setTimeout(() => {
        this.showfinalSuccessMsg = "";
        this.showToastermessage = false;
      }, 3000);
    }
    this.spinner.hide();
  }
  pageChanged(event) {
    this.pgIndex = event.pageIndex?event.pageIndex:0;
    this.pgIndex = this.pgIndex + 1;
    this.currentPage = event.pageIndex?event.pageIndex:0;
    this.isLoading = true;
    this.getGurulist();
  }
  formControlValue(event){
    this.currentPage=0;
    this.formcontrolValues = event;
    this.getGurulist(event);
  }


  setGuruListTab(status) {
    this.step = status;
    this.commonService.changeGurustep(status.trim());
    //localStorage.setItem("step", status);
    //reset page index
    this.pgIndex = 1;
    this.currentPage = 0;
    this.isLoading = true;
    this.getGurulist();
  }
  actionAPMBtn(action) {
    var checkedValue = [];
    const inputElements = document.getElementsByClassName(
      "messageCheckbox"
      ) as unknown as HTMLInputElement;
    for (var i = 0; inputElements[i]; ++i) {
      //debugger;
      if (inputElements[i].checked) {
        checkedValue.push(inputElements[i].id);
      }
    }
    let userObj;
    let url = "";
    if (action === "Approve") {
      userObj = {
        guru_id: checkedValue,
      };
      url = this.appService.pendingGuruApprove;
    } else {
      userObj = {
        guru_id: checkedValue,
        reason: "Rejected",
      };
      url = this.appService.pendingGuruReject;
    }
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: userObj,
    };
    this.commonService.commonApiCall(apiObj, (res, error) => {
      if (error != undefined) {
        this.showToastermessage = true;
        this.showfinalSuccessMsg = "";
        this.showfinalErrorMsg = error.error.msg;
        setTimeout(() => {
          this.showToastermessage = false;
        }, 4000);
      } else {
        this.showfinalErrorMsg = "";
        this.showfinalSuccessMsg = res.msg;
        this.showToastermessage = true;
        setTimeout(() => {
          this.showToastermessage = false;
        }, 4000);
      }

      this.getGurulist();
    });
  }

  rejectProfile(action) {
    var checkedValue = [];
    const inputElements = document.getElementsByClassName(
      "messageCheckbox"
    ) as unknown as HTMLInputElement;
    for (var i = 0; inputElements[i]; ++i) {
      //debugger;
      if (inputElements[i].checked) {
        checkedValue.push(inputElements[i].id);
      }
    }
    if(checkedValue.length > 0){
      let dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: "607px",
        height: "398px",
        disableClose: true,
      });
      dialogRef.componentInstance.gurucheckedValue = checkedValue;
      dialogRef.componentInstance.action = action;
      dialogRef.componentInstance.guruFlagSource = "list";
    }else{
      this.showToastermessage = true;
        this.showfinalSuccessMsg = "";
        this.showfinalErrorMsg = "No guru is selected to be rejected, please select at least one guru";
        setTimeout(() => {
          this.showToastermessage = false;
        }, 3000);
    }
  }

  updateGuru(id) {
    this.router.navigate(["update-guru/" + id]);
  }
  acceptGuru(id) {
    let userObj = {
      added_by: JSON.parse(localStorage.getItem("loginUser")).id,
    };
    // Subscribeconsole.log(userObj);
    const url = `${this.appService.acceptGuru + id}/`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: userObj,
    };
    this.commonService.commonApiCall(apiObj, (res, error) => {
      this.getGurulist();
    });
  }

  toggleStarGuruStatus(isChecked: boolean, row){
    const ListInput = [{
      "id": row.id,
      "is_star_guru": isChecked ? 'True' : 'False'
    }];
  
    try {
      this.spinner.show();
      this.sub.add(this.dashboard.starGuruUpdate(ListInput).subscribe({
        next: (response) => {
          this.spinner.hide();
          if (response.success) {
            row.is_star_guru = isChecked ? 'True' : 'False';
            Swal.fire({
              icon: 'success',
              text: 'The Star Guru status has been updated.',
              timer: 2000, 
              showConfirmButton: false
            });
          } else {
            this.showToastermessage = true;
            this.showfinalSuccessMsg = "";
            this.showfinalErrorMsg = "Error. Please try again after some time";
            setTimeout(() => {
            this.showToastermessage = false;
          }, 2000);
          }
        },
        error: (err) => {
          throw(err)
        }
      }))
    }
    catch(error){
      throw(error);
    }
  }
  selectedGuru = {
    first_name: '',
    last_name: '',
    state: '',
    city: '',
    district: '',
    taluka: '',
    pincode: '',
    account_id: '',
    guru_phone_number: '',
    garage_name: '',
    editFields: {
      first_name: false,
      last_name: false,
      state: false,
      city: false,
      district: false,
      pincode: false,
      account_id: false,
      garage_name: false,
      mobile: false,
    },
    isEditingName: false, // New property for toggling name edit
  };
  
  // Toggle between name display and separate fields for editing
  toggleEditName() {
    this.selectedGuru.isEditingName = !this.selectedGuru.isEditingName;
  }
  
  updateguruInfo(){
    try {
      let data={
        first_name: this.selectedGuru.first_name,
        last_name:this.selectedGuru.last_name,
        garage_name: this.selectedGuru.garage_name,
        account_id: this.selectedGuru.account_id,
        mobile_no: this.selectedGuru.guru_phone_number,
        state: this.selectedGuru.state,
        city: this.selectedGuru.city,
        district: this.selectedGuru.district,
        pincode: this.selectedGuru.pincode,
      }
      this.dashboard.mechanicGuruUpdate(data).subscribe({
        next: (response) => {
          if (response.success === true) {
            this.showToastermessage = true;
            this.showfinalSuccessMsg = response.msg;
            this.modalRef?.hide();
            this.showfinalErrorMsg = "";
            setTimeout(() => {
              this.showToastermessage = false;
            }, 4000);
          } else {
            this.showToastermessage = true;
            this.showfinalSuccessMsg = "";
            this.showfinalErrorMsg = response.msg.msg;
            setTimeout(() => {
              this.showToastermessage = false;
            }, 4000);
          }
        },
        error: (err) => {
          throw(err)
        }
      })
    } 
    catch (error) {
      
    }
  }

  pincodeBasedAllLocationsData: any;
  states:any = [];
  // getPincode(): boolean {
  //   let pincode =this.selectedGuru.pincode ;
  //   console.log('pincode',pincode);
    
  //   let requestPayload = { "pincode": pincode, "app_name": "bandhu", "device_id": "24872681273" };
  //   let isSuccessful = false;

  //   this.dashboard.pinCode(requestPayload).subscribe({
  //     next: (response) => {
  //       if (response) {
  //         this.pincodeBasedAllLocationsData = response;

  //         for (let x = 0; x < response.length; x++) {
  //           let loc = response[x];
  //           if (this.states) {
  //             let state_codes = this.states.map((sc) => sc["code"]);
  //             if (!(state_codes && state_codes.includes(loc["state"]["code"]))) {
  //               this.states.push({ "code": loc["state"]["code"], "name": loc["state"]["name"] });
  //             }
  //           } else {
  //             this.states.push({ "code": loc["state"]["code"], "name": loc["state"]["name"] });
  //           }
  //         }
  //         isSuccessful = true;
  //       } else {
  //         console.warn("Pincode not found.");
  //         isSuccessful = false;
  //       }
  //     },
  //     error: (error) => {
  //       console.error("API error occurred:", error);
  //       isSuccessful = false;
  //     }
  //   });

  //   return isSuccessful;
  // }
  stateSelected: any;
  districts:any = [];
	talukas:any = [];
	cities:any = [];
  getFilteredDistrict(): void {
    let state_selected = this.states.filter((x: any, j: number) => {
      if (this.selectedGuru.state) {
        if (x["code"] == this.selectedGuru.state) {
          this.stateSelected = x;
        }
        return (x["code"] == this.selectedGuru.state);
      } else {
        return true;
      }
    });

    var state_selected_codes = state_selected.map((x: any) => { return x["code"] });
    let filtered_districts = this.pincodeBasedAllLocationsData.filter((item: any, i: number) => {
      return (typeof item["state"] != "undefined" && typeof item["state"]["code"] != "undefined" && state_selected_codes.includes(item["state"]["code"]));
    });

    // this.districts = []; // Clear previous districts
    for (let i = 0; i < filtered_districts.length; i++) {
      const loc = filtered_districts[i];
      if (this.districts && this.districts.length) {
        let district_codes = this.districts.map((x) => x["code"]);
        if (!(district_codes && district_codes.includes(loc["district"]))) {
          this.districts.push({ "code": loc["district"], "name": loc["district"] });
        }
      } else {
        this.districts.push({ "code": loc["district"], "name": loc["district"] });
      }
    }



  
  }


  getFilteredTalukas(): void {
    let district_selected = this.districts.filter((x: any, j: number) => {
      if (this.selectedGuru.district) {
        return (x["code"] == this.selectedGuru.district);
      } else {
        return true;
      }
    });

    var district_selected_codes = district_selected.map((x: any) => { return x["code"] });

    let filtered_talukas = this.pincodeBasedAllLocationsData.filter((item: any, i: number) => {
      return (typeof item["district"] != "undefined" && district_selected_codes.includes(item["district"]));
    });

    // this.talukas = []; // Clear previous talukas
    for (let i = 0; i < filtered_talukas.length; i++) {
      const loc = filtered_talukas[i];
      if (this.talukas && this.talukas.length) {
        let code_list = this.talukas.map((x) => x["code"]);
        if (!(code_list && code_list.includes(loc["taluka"]))) {
          this.talukas.push({ "code": loc["taluka"], "name": loc["taluka"], "data": loc });
        }
      } else {
        this.talukas.push({ "code": loc["taluka"], "name": loc["taluka"], "data": loc });
      }
    }



  }

  getFilteredCities(): void {
    let taluka_selected = this.talukas.filter((x: any, j: number) => {
      if (this.selectedGuru.taluka) {
        return (x["code"] == this.selectedGuru.taluka);
      } else {
        return true;
      }
    });
    var taluka_selected_codes = taluka_selected.map((x: any) => { return x["code"] });
    let filtered_cities = this.pincodeBasedAllLocationsData.filter((item: any, i: number) => {
      return (typeof item["taluka"] != "undefined" && taluka_selected_codes.includes(item["taluka"]));
    });
    for (let i = 0; i < filtered_cities.length; i++) {
      const loc = filtered_cities[i];
      if (this.cities && this.cities.length) {
        let code_list = this.cities.map((x) => x["code"]);
        if (!(code_list && code_list.includes(loc["city"]))) {
          this.cities.push({ "code": loc["city"], "name": loc["city"] });
        }
      } else {
        this.cities.push({ "code": loc["city"], "name": loc["city"] });
      }
    }
  }

  filterStateDistrictTalukaCity(boolIsStateChanged: boolean = false): any {
    this.getFilteredDistrict();
    this.getFilteredTalukas();
    this.getFilteredCities();
  }

  stateChanged(): void {
    this.cities = [];
    this.talukas = [];
    this.districts = [];
    this.selectedGuru.district='';
    this.selectedGuru.taluka='';
    this.selectedGuru.city='';
    this.filterStateDistrictTalukaCity(false);
    // this.getRegion();
    // this.getServiceDealers()
  }
  districtChanged(): void {
    this.cities = [];
    this.talukas = [];
    this.selectedGuru.taluka='';
    this.selectedGuru.city='';
    this.filterStateDistrictTalukaCity(false);
    // this.getSalesDealers();
    // this.getDistributors();

  }
  validatePincode(): void {
    const pincode = this.selectedGuru.pincode;
  
    // Clear values if pincode is empty or less than 6 digits
    if (!pincode || pincode.length < 6) {
      this.selectedGuru.state = '';
      this.selectedGuru.district = '';
      this.selectedGuru.city = '';
      this.states = [];
      this.districts = [];
      this.cities = [];
      this.talukas = [];
      return;
    }
  
    // Only proceed with API call if pincode is exactly 6 digits
    if (pincode.length === 6 && /^\d+$/.test(pincode)) {
      const requestPayload = {
        "pincode": pincode,
        "app_name": "bandhu",
        "device_id": "24872681273"
      };
  
      this.dashboard.pinCode(requestPayload).subscribe({
        next: (response) => {
          if (response) {
            this.pincodeBasedAllLocationsData = response;
            
            // Process states
            this.states = [];
            response.forEach(loc => {
              if (!this.states.some(state => state.code === loc.state.code)) {
                this.states.push({
                  "code": loc.state.code,
                  "name": loc.state.name
                });
              }
            });
  
            // Find and set the matching state
            const matchingLocation = response[0];
            if (matchingLocation) {
              this.selectedGuru.state = matchingLocation.state.code;
              
              // Set district
              this.getFilteredDistrict();
              if (this.districts.length > 0) {
                this.selectedGuru.district = matchingLocation.district;
              }
  
              // Set taluka
              this.getFilteredTalukas();
              if (this.talukas.length > 0) {
                this.selectedGuru.taluka = matchingLocation.taluka;
              }
  
              // Set city
              this.getFilteredCities();
              if (this.cities.length > 0) {
                this.selectedGuru.city = matchingLocation.city;
              }
            }
          } else {
            // Clear values if no response
            this.selectedGuru.state = '';
            this.selectedGuru.district = '';
            this.selectedGuru.city = '';
            this.states = [];
            this.districts = [];
            this.cities = [];
            this.talukas = [];
            console.warn("Pincode not found.");
          }
        },
        error: (error) => {
          // Clear values on error
          this.selectedGuru.state = '';
          this.selectedGuru.district = '';
          this.selectedGuru.city = '';
          this.states = [];
          this.districts = [];
          this.cities = [];
          this.talukas = [];
          console.error("API error occurred:", error);
        }
      });
    }
  }

 
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
export interface GuruList{
  Id:any
  Guru_Name:any
  Registration_Date:any
  Garage_Name:any
  Garage_Area:any
  Garage_Id:any
  Ratings:any
  Guru_Phone_Number:any
  Total_Part_Fitted:any
  Total_Jobcards:any
  Rejected_On:any
  Rejection_Reason:any
  Associated_Since:any
  Source:any
  Dealer:any
  Sso:any
  Zone:any
  State_Name:any
  District:any
  Pincode:any
  Membership:any
  Lass_Name:any
  Lass_Number:any
  Lass_Email:any
  Msr_Id:any
  Msr_Name:any
  Msr_Mobile_Number:any
}