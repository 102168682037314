<div class="tb-table-wrapper">
    <div class="guru-tabs-row d-flex" style="justify-content: end;">
        <div class="guru-tabs-items">
            <div class="tb-btn-group">
                <ul>
                  <li *ngIf="!isAddSalesDealer">
                    <button mat-stroked-button class="primary-stroke-button exportBtn" (click)="reset()">
                        Refresh
                    </button>
                </li>
                  <li *ngIf="!isAddSalesDealer">
                    <div class="tb-filter-wrapper">
                        <button mat-stroked-button class="primary-stroke-button filterBtn" (click)="openDropdown()" [ngClass]="{ open: isOpenDropdown }">
                        Filter
                        </button>
                        <div class="custom-filter-sec" *ngIf="isOpenDropdown">
                        <h3>
                            Filter <button class="filterCloseBtn" (click)="closeDropdown()"></button>
                        </h3>
                        <form [formGroup]="SalesDealerFilterForm" name="LassFilterForm" class="form">
                                <div class="custom-filter-body" style="justify-content: space-evenly;">
                                </div>
                                <div class="custom-filter-body" style="justify-content: space-evenly;">
                                  <!-- <div class="custom-filter-col">
                                        <mat-form-field>
                                            <mat-label>Mobile Number</mat-label>
                                            <input formControlName="mobileNumber" matInput (keydown.enter)="applyFilter()" maxlength="10"
                                            (keypress)="keyPressNumbers($event)">
                                        </mat-form-field>
                                    </div> -->
                                    <div class="custom-filter-col">
                                      <mat-form-field >
                                          <mat-label>District</mat-label>
                                          <input formControlName="District" matInput>
                                        </mat-form-field>
                                    </div>
                                      <div class="custom-filter-col">
                                        <mat-form-field >
                                            <mat-label>Dealer</mat-label>
                                            <input formControlName="Dealer" matInput>
                                          </mat-form-field>
                                      </div>
                                      <div class="custom-filter-col">
                                        <mat-form-field >
                                            <mat-label>Row Id</mat-label>
                                            <input formControlName="RowID" matInput>
                                          </mat-form-field>
                                      </div>
                                      <div class="custom-filter-col">
                                        <mat-form-field>
                                          <mat-label for="State" class="form-label">Select State </mat-label>
                                          <mat-select formControlName="State" multiple>
                                            <!-- <mat-option value="">Please select State</mat-option> -->
                                            <mat-option [value]="state.state_name" *ngFor="let state of states">
                                              {{ state.state_name }}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                      </div>
                                      <div class="custom-filter-col">
                                        <mat-form-field>
                                          <mat-label label for="Region" class="form-label"> Select region </mat-label>
                                          <mat-select formControlName="Region" multiple>
                                              <mat-option [value]="region" *ngFor="let region of regions ">{{ region }}</mat-option>
                                          </mat-select>
                                      </mat-form-field>
                                      </div>
                                    <div class="custom-filter-col"></div>
                                </div>
                                <div class="btn-group filterBtn-group">
                                    <button mat-stroked-button class="primary-stroke-button"(click)="reset()" >Reset</button>
                                    <button mat-flat-button class="primary-flat-button" (click)="applyFilter()">Apply</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </li>
                    <li>
                        <div class="addUser-btn">
                            <button mat-flat-button matStepperNext class="primary-flat-button" 
                            (click)="isAddSalesDealer = !isAddSalesDealer">
                            {{isAddSalesDealer ? 'Back' : 'Add Sales Dealer'}}
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="form-wrap comn-box add-item-content" *ngIf="isAddSalesDealer">
    <form
      [formGroup]="addSalesDealerForm"
      (ngSubmit)="addSalesDealerFormData()"
      autocomplete="off"
    >
      <div class="form-section">
        <div class="form-row">
          <div class="form-col">
            <label for="rowId" class="form-label" 
              >Row Id <span class="requiredfield">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="rowId"
              formControlName="rowId"
              maxlength="30"
              placeholder="Enter Row Id"
            />
            <div
              *ngIf="
                submitted && this.addSalesDealerForm.controls.rowId.errors
              "
              class="invalid-field"
            >
              <span
                *ngIf="this.addSalesDealerForm.controls.rowId.errors.required"
                >Row Id is required</span
              >
              <span
                *ngIf="this.addSalesDealerForm.controls.rowId.errors.pattern"
                >Please enter valid Row Id</span
              >
              <span
                *ngIf="
                  !this.addSalesDealerForm.controls.rowId.errors.required &&
                  this.addSalesDealerForm.controls.rowId.errors.whitespace
                "
                >Please enter valid Row Id</span
              >
            </div>
          </div>
          <div class="form-col">
            <label for="dealer" class="form-label" 
            >Dealer<span class="requiredfield">*</span></label
          >
          <input
              type="text"
              class="form-control"
              id="dealer"
              formControlName="dealer"
              maxlength="30"
              placeholder="Enter Dealer"
            />
  
            <div
              *ngIf="
                submitted && this.addSalesDealerForm.controls.dealer.errors
              "
              class="invalid-field"
            >
              <span
                *ngIf="this.addSalesDealerForm.controls.dealer.errors.required"
                >Dealer is required</span
              >
              <span
                *ngIf="this.addSalesDealerForm.controls.dealer.errors.pattern"
                >Please enter Dealer</span
              >
              <span
                *ngIf="
                  !this.addSalesDealerForm.controls.dealer.errors.required &&
                  this.addSalesDealerForm.controls.dealer.errors.whitespace
                "
                >Please enter valid Dealer</span
              >
            </div>
          </div>
          <div class="form-col">
            <label for="stateOffice" class="form-label" 
            >State Office <span class="requiredfield">*</span></label
          >
          <input
              type="text"
              class="form-control"
              id="stateOffice"
              formControlName="stateOffice"
              maxlength="30"
              placeholder="Enter state office"
            />
            <div
            *ngIf="
              submitted && this.addSalesDealerForm.controls.stateOffice.errors
            "
            class="invalid-field"
          >
            <span
              *ngIf="this.addSalesDealerForm.controls.stateOffice.errors.required"
              >State office is required</span
            >
            <span
              *ngIf="this.addSalesDealerForm.controls.stateOffice.errors.pattern"
              >Please enter State office</span
            >
            <span
              *ngIf="
                !this.addSalesDealerForm.controls.stateOffice.errors.required &&
                this.addSalesDealerForm.controls.stateOffice.errors.whitespace
              "
              >Please enter valid State Office</span
            >
          </div>
          </div>
        </div>
        
      </div>

      <div class="form-row">
        <div class="form-col">
          <label for="district" class="form-label" 
          >District <span class="requiredfield">*</span></label
        >
        <input
            type="text"
            class="form-control"
            id="district"
            formControlName="district"
            maxlength="30"
            placeholder="Enter District"
          />
          <div
          *ngIf="
            submitted && this.addSalesDealerForm.controls.district.errors
          "
          class="invalid-field"
        >
          <span
            *ngIf="this.addSalesDealerForm.controls.district.errors.required"
            >District is required</span
          >
          <span
            *ngIf="this.addSalesDealerForm.controls.district.errors.pattern"
            >Please enter District</span
          >
          <span
            *ngIf="
              !this.addSalesDealerForm.controls.district.errors.required &&
              this.addSalesDealerForm.controls.district.errors.whitespace
            "
            >Please enter valid District</span
          >
        </div>
        </div>
        
        <div class="form-col">
          <label for="orginaztionType" class="form-label" 
          >Organization type <span class="requiredfield">*</span></label
        >
        <input
            type="text"
            class="form-control"
            id="orginaztionType"
            formControlName="orginaztionType"
            maxlength="30"
            placeholder="Enter orginaztion type"
          />
          <div
          *ngIf="
            submitted && this.addSalesDealerForm.controls.orginaztionType.errors
          "
          class="invalid-field"
        >
          <span
            *ngIf="this.addSalesDealerForm.controls.orginaztionType.errors.required"
            >Organization type is required</span
          >
          <span
            *ngIf="this.addSalesDealerForm.controls.orginaztionType.errors.pattern"
            >Please enter Organization type</span
          >
          <span
            *ngIf="
              !this.addSalesDealerForm.controls.orginaztionType.errors.required &&
              this.addSalesDealerForm.controls.orginaztionType.errors.whitespace
            "
            >Please enter valid Organization type</span
          >
        </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-col">
          <label for="stateDetail" class="form-label">State<span class="requiredfield">*</span></label>
          <mat-form-field>
              <mat-label label for="stateDetail" class="form-label"> Select State <span class="requiredfield">*</span> </mat-label>
              <mat-select formControlName="stateDetail" >
                  <mat-option [value]="state.state_name" *ngFor="let state of states ">{{ state.state_name }}</mat-option>
              </mat-select>
          </mat-form-field>
          <div *ngIf="submitted && addSalesDealerForm.get('stateDetail').invalid" class="invalid-field">
              <span>State is required</span>
          </div>
          <div *ngIf="submitted && addSalesDealerForm.get('stateDetail').invalid && addSalesDealerForm.get('stateDetail').touched" class="invalid-field">
              <span>State is required</span>
          </div>
      </div>
        <div class="form-col">
          <label for="region" class="form-label">Region<span class="requiredfield">*</span></label>
          <mat-form-field>
              <mat-label label for="region" class="form-label"> Select region <span class="requiredfield">*</span></mat-label>
              <mat-select formControlName="region" >
                  <mat-option [value]="region" *ngFor="let region of regions ">{{ region }}</mat-option>
              </mat-select>
          </mat-form-field>
          <div *ngIf="submitted && addSalesDealerForm.get('region').invalid && addSalesDealerForm.get('region').touched" class="invalid-field">
              <span>Region is required</span>
          </div>
          <div *ngIf="submitted && addSalesDealerForm.get('region').invalid" class="invalid-field">
              <span>Region is required</span>
          </div>
      </div>
      </div>

      <div class="form-row">
        
      </div>
      <div class="addLass-btn mt-3">
        <button
          mat-flat-button
          matStepperNext
          class="primary-flat-button"
          type="submit"
        >
          Add Sales Dealer
        </button>
      </div>
    </form>
  </div>

<div class="table-wrapper" *ngIf="!isAddSalesDealer">
    <div class="guru-list-table" *ngIf="!showNodata">
    <table
        mat-table
        matTableExporter
        class="full-width-table tb-table"
        matSort
        aria-label="Elements"
        [dataSource]="dataSource"
    >
        
        <ng-container matColumnDef="row_id">
            <th class="row_id-col" mat-header-cell *matHeaderCellDef>
              Row Id
            </th>
            <td class="row_id-col" mat-cell *matCellDef="let row">
                {{ row.row_id}}
            </td>
        </ng-container>
        <ng-container matColumnDef="Region">
            <th class="Region-col" mat-header-cell *matHeaderCellDef>
            Region
            </th>
            <td class="Region-col" mat-cell *matCellDef="let row">
            {{ row.region}}
            </td>
        </ng-container>
        <ng-container matColumnDef="StateOffice">
            <th class="StateOffice-col" mat-header-cell *matHeaderCellDef>
              State Office
            </th>
            <td class="StateOffice-col" mat-cell *matCellDef="let row">
                {{ row.state_office }}
            </td>
        </ng-container>

        <ng-container matColumnDef="StateDetail">
          <th class="StateDetail-col" mat-header-cell *matHeaderCellDef>State Detail</th>
            <td class="StateDetail-col" mat-cell *matCellDef="let row">
                {{ row.state_detail }}
            </td>
        </ng-container>

        <ng-container matColumnDef="District">
          <th class="District-col" mat-header-cell *matHeaderCellDef>District</th>
            <td class="District-col" mat-cell *matCellDef="let row">
                {{ row.district }}
            </td>
        </ng-container>

        <ng-container matColumnDef="Dealer">
          <th class="Dealer-col" mat-header-cell *matHeaderCellDef>Dealer</th>
            <td class="Dealer-col" mat-cell *matCellDef="let row">
                {{ row.dealer }}
            </td>
        </ng-container>

        <ng-container matColumnDef="OrginaztionType">
          <th class="OrginaztionType-col" mat-header-cell *matHeaderCellDef>Orginaztion Type</th>
            <td class="OrginaztionType-col" mat-cell *matCellDef="let row">
                {{ row.org_type }}
            </td>
        </ng-container>

        
        
        
        <tr
        class="tb-head-row"
        mat-header-row
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
        class="tb-item-row"
        mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
    </table>
    </div>

    <ng-container *ngIf="showNodata">
        <div class="no-item-found">No record found</div>
    </ng-container>

    <mat-paginator
      appPagination
      showFirstLastButtons
      [length]="totalRows"
      [pageIndex]="currentPage"
      [pageSize]="pageSize"
      [hidden]="showNodata"
      (page)="pageChanged($event)"
      class="custom-paginator"
      [hidePageSize]="true"
    >
    </mat-paginator>
</div>
  <div class="action-toaster" *ngIf="showToastermessage">
    <div class="toaster-msg alert alert-success" *ngIf="showfinalSuccessMsg">
    {{ showfinalSuccessMsg }}
    </div>
    <div class="toaster-msg alert alert-danger" *ngIf="showfinalErrorMsg">
    {{ showfinalErrorMsg }}
    </div>
</div>

<ngx-spinner></ngx-spinner>