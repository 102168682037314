import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProblemSubProblemFilterComponent } from './problem-sub-problem-filter/problem-sub-problem-filter.component';
import { HttpMethod } from 'src/app/constants/http-handlers';
import { CommonService } from 'src/app/services/common.service';
import { AppService } from 'src/app/services/app.service';
import { DatePipe } from '@angular/common';
import { ExcelService } from 'src/app/services/excel.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-problem-sub-problem',
  templateUrl: './problem-sub-problem.component.html',
  styleUrls: ['./problem-sub-problem.component.scss']
})
export class ProblemSubProblemComponent implements OnInit {

  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('childComponent', {static: false}) childComponent:ProblemSubProblemFilterComponent;
  displayedColumns: string[] = [
    "problemId",
    "problemCreatedDate",
    "problem",
    "subProblem",
    "jobCardId",
    "jobCardCaseId",
    "jobCardCreatedDate",
    "jobCardUpdatedDate",
    "userID",
    "garageName",
    "guruId",
    "guruName",
    "guruContactNo",
    "guruState",
    "guruRegion",
    "lassName",
    "lassNumber"
];
  showNodata: boolean = false;
  isLoading:boolean = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pgIndex: number = 0;
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;
  formcontrolValues:any;
  role: string;
  zone: any;
  
  constructor( 
    private commonService:CommonService, 
    private appService:AppService,
    private datePipe:DatePipe,
    private excelService:ExcelService,
    private spinner: NgxSpinnerService,
    ) { }
  ngOnInit(): void {
    this.role = JSON.parse(localStorage.getItem("userDetail")).userdetails.role;
if (this.role === 'RPM'||this.role === 'DEALER') {
  this.zone = JSON.parse(localStorage.getItem('userDetail')).userdetails.region;
}
    this.problemSubProblemlist();
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  problemSubProblemlist(formVal?) {
    try{
      let apiUrl = this.appService.problemSubProblemReport;
      const url = `${apiUrl}`;
      let date = new Date();
      let toDate = this.datePipe.transform(formVal?formVal.toDate:date, "yyyy-MM-dd");
      let fromDate = this.datePipe.transform(formVal?formVal.fromDate:date.setMonth(date.getMonth() - 1), "yyyy-MM-dd");
      let req = {
        "to_date":toDate,
        "from_date":fromDate,
        "job_id":"",
        "lass_phno":this.formcontrolValues?this.formcontrolValues.lassContactNo:"",
        "lass_name":this.formcontrolValues?this.formcontrolValues.lassnme:"",
        "guru_phno":this.formcontrolValues?this.formcontrolValues.mobileNo:"",
        "guru_name":this.formcontrolValues?this.formcontrolValues.guruName:"",
        "region": this.role === 'RPM'||this.role === 'DEALER' ? this.zone : this.formcontrolValues?this.formcontrolValues.zone:"",
        "jobcard_problem":this.formcontrolValues?this.formcontrolValues.problem:"",
        "jobcard_sub_problem":this.formcontrolValues?this.formcontrolValues.jobcard_sub_problem:"",
        "offset": this.currentPage == 0 ?0:(this.currentPage * 10),
        "limit": 10
      }
      const apiObj = {
        requestObj:req,
        url: url,
        methodType: HttpMethod.POST,
      };
      this.spinner.show();
      this.commonService.commonApiCall(apiObj, (res) => {
        if (
          res &&
          res.hasOwnProperty("result") &&
          res.result &&
          res.result.length
        ) {
          this.spinner.hide();
          if(res.success == true){
            this.dataSource = new MatTableDataSource(res.result);
            this.dataSource.sort = this.sort;
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = res.count;
            // this.childComponent.closeDropdown();
            this.isLoading = false;
            this.showNodata = false;
          }else{
            this.showfinalErrorMsg = res.msg;
            this.showToastermessage = true;
            this.showNodata = true;
          }
        }else {
          this.showNodata = true;
          this.spinner.hide();
          this.showfinalErrorMsg = res.error.msg;
          this.childComponent.closeDropdown();
          this.showToastermessage = true;
        }
      });
    }catch(error){
      this.spinner.hide();
      this.showfinalErrorMsg = error.error.msg;
      this.showToastermessage = true;
      this.showNodata = true;
    }
  }
  async downloadProblemSubProblemListListExcel(){
    try{
      this.spinner.show();
      let apiUrl =  this.appService.problemSubProblemReport;
      let date = new Date();
      let toDate = this.datePipe.transform(this.formcontrolValues?this.formcontrolValues.toDate:date, "yyyy-MM-dd");
      let fromDate = this.datePipe.transform(this.formcontrolValues?this.formcontrolValues.fromDate:date.setMonth(date.getMonth() - 1), "yyyy-MM-dd");
      let req = {
        "to_date":toDate?toDate:this.datePipe.transform(date,"yyyy-MM-dd"),
        "from_date":fromDate?fromDate:this.datePipe.transform(date.setMonth(date.getMonth() - 3),"yyyy-MM-dd"),
        "job_id":"",
        "lass_phno":this.formcontrolValues?this.formcontrolValues.lassContactNo:"",
        "lass_name":this.formcontrolValues?this.formcontrolValues.lassnme:"",
        "guru_phno":this.formcontrolValues?this.formcontrolValues.mobileNo:"",
        "guru_name":this.formcontrolValues?this.formcontrolValues.guruName:"",
        "region":this.formcontrolValues?this.formcontrolValues.zone:"",
        "jobcard_problem":this.formcontrolValues?this.formcontrolValues.problem:"",
        "jobcard_sub_problem":this.formcontrolValues?this.formcontrolValues.jobcard_sub_problem:"",
        "offset": 0,
        "limit": 250
      }
      console.log('req',req);
      
      let totalResult=0;
      let data:any[] = [];
      let res = await this.commonService.postAsyncRequest(apiUrl,req);
      if(res){
        totalResult = res.count;
        data = res.result;
      }
      let rou = Math.floor(totalResult/250);
      rou = (totalResult%250>0) && (rou > 0)?rou+1:rou; 
      if(rou > 1 ){
        for(var i=1;i<rou;i++){
          req.offset = (i * 250);
          res = await this.commonService.postAsyncRequest(apiUrl,req);
          if(res && res.result && res.result.length){
            data = [...data, ...res.result];
          }
        }
      }
      let finalData:ProblemSubProblemReport[] = [];
      for (let row of data) {
        const ListInput: ProblemSubProblemReport = {} as ProblemSubProblemReport;
        ListInput.Problem_ID =   row.id
        ListInput.Created_Date = this.datePipe.transform(row.created_date, "dd-MM-yyyy")
        ListInput.Problem = row.problem.problem_english
        ListInput.Sub_Problem = row.sub_problem.length?row.sub_problem.map(e => e.replace(/\s/g, " ")).join(", ") :"NA"
        ListInput.Job_card_Id =   row.jobcard?row.jobcard.jobcard_id:""
        ListInput.Job_Card_Case_Id = row.jobcard?row.jobcard.case_id:""
        ListInput.Job_Created_Date =  row.jobcard?this.datePipe.transform(row.jobcard.jc_created_date, "dd-MM-yyyy"):""
        ListInput.Job_Card_Updated_Date = row.jobcard?this.datePipe.transform(row.jobcard.jc_updated_date,"dd-MM-yyyy"):""
        ListInput.User_Id = row.jobcard?row.jobcard.user_id:""
        ListInput.Garage_Name = row.jobcard?row.jobcard.garage_name:""
        ListInput.Guru_Id = row.jobcard?row.jobcard.guru_id:""
        ListInput.Guru_Name = row.jobcard?row.jobcard.guru_name:""
        ListInput.Guru_Contact_Number = row.jobcard?row.jobcard.guru_mobile_no:""
        ListInput.Guru_State = row.jobcard && row.jobcard.state_name?row.jobcard.state_name:"NA"
        ListInput.Guru_Region = row.jobcard && row.jobcard.region?row.jobcard.region:"NA"
        ListInput.Lass_Name= row.jobcard?row.jobcard.lass_name:"NA"
        ListInput.Lass_Number=row.jobcard?row.jobcard.lass_number:"NA"
        finalData.push(ListInput);
      }
      if(finalData.length > 0 ){
        this.excelService.exportCancellationAsExcelFile(finalData, 'problem_sub_problem_report');
      }else{
        this.showToastermessage = true;
        this.showfinalErrorMsg =
          "No Data For Download";
        setTimeout(() => {
          this.showfinalErrorMsg = "";
          this.showToastermessage = false;
        }, 3000);
      }
      
    }catch(error){
      this.showToastermessage = true;
      this.showfinalSuccessMsg =
        "No Data For Download";
      setTimeout(() => {
        this.showfinalSuccessMsg = "";
        this.showToastermessage = false;
      }, 3000);
    }
    this.spinner.hide();
  }
  pageChanged(event) {
    this.pgIndex = event.pageIndex?event.pageIndex:0;
    this.pgIndex = this.pgIndex + 1;
    this.currentPage = event.pageIndex?event.pageIndex:0;
    this.isLoading = true;
    this.problemSubProblemlist(this.formcontrolValues);
  }
  formControlValue(event){
    this.currentPage=0;
    this.formcontrolValues = event;
    console.log('this.formcontrolValues',this.formcontrolValues);
    
    this.problemSubProblemlist(event);
  }

}

export interface ProblemSubProblemReport{
  Problem_ID:any	
  Created_Date:any
  Problem:any
  Sub_Problem:any	
  Job_card_Id:any
  Job_Card_Case_Id:any	
  Job_Created_Date:any	
  Job_Card_Updated_Date:any	
  User_Id:any
  Garage_Name:any	
  Guru_Id:any	
  Guru_Name:any	
  Guru_Contact_Number:any	
  Guru_State:any	
  Guru_Region:any	
  Lass_Name:any	
  Lass_Number:any
}