import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpMethod } from 'src/app/constants/http-handlers';
import { AppService } from 'src/app/services/app.service';
import { CommonService } from 'src/app/services/common.service';
import { ExcelService } from 'src/app/services/excel.service';
import { DoorStepBookingFilterComponent } from '../door-step-booking-report/door-step-booking-filter/door-step-booking-filter.component';

@Component({
  selector: 'app-referrel-reports',
  templateUrl: './referrel-reports.component.html',
  styleUrls: ['./referrel-reports.component.scss']
})
export class ReferrelReportsComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('childComponent', {static: false}) childComponent:DoorStepBookingFilterComponent;
  showNodata: boolean = false;
  isLoading:boolean = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pgIndex: number = 0;
  showfinalSuccessMsg: string;
  showfinalErrorMsg: string;
  showToastermessage: boolean = false;
  formcontrolValues:any;
  referrelStep:string="prospect";
  role: string;
  zone: any;
  
  constructor( 
    private commonService:CommonService, 
    private appService:AppService,
    private datePipe:DatePipe,
    private excelService:ExcelService,
    private spinner: NgxSpinnerService,
    ) { }
  ngOnInit(): void {
    this.role = JSON.parse(localStorage.getItem("userDetail")).userdetails.role;
if (this.role === 'RPM'||this.role === 'DEALER') {
  this.zone = JSON.parse(localStorage.getItem('userDetail')).userdetails.region;
}
    this.getReferrelReportlist();
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  getReferrelReportlist(formVal?) {
    try{
      let apiUrl = this.appService.referrelReport;
      const url = `${apiUrl}`;
      let date = new Date();
      let toDate = this.datePipe.transform(formVal?formVal.toDate:date, "yyyy-MM-dd");
      let fromDate = this.datePipe.transform(formVal?formVal.fromDate:date.setMonth(date.getMonth() - 1), "yyyy-MM-dd");
      let req = {
        "to_date": toDate,
        "from_date": fromDate,
        "referral_report_type": this.referrelStep ? this.referrelStep : "prospect",
        "guru_phone_number": "",
        "customer_phone_number": this.formcontrolValues ? this.formcontrolValues.custmobileNo : "",
        "prospect_phone_number": this.formcontrolValues ? this.formcontrolValues.prospectmobileNo : "",
        "lass_name": this.formcontrolValues ? this.formcontrolValues.lassName : "",
        "lass_phone_number": this.formcontrolValues ? this.formcontrolValues.lassNumber : "",
        "guru_state": "",
        "region": this.role === 'RPM'||this.role === 'DEALER' ? this.zone : "",
        "referred_by_guru_state": "",
        "referred_by_guru_region": "",
        "referred_by_guru_phone_number": "",
        "guru_city": this.formcontrolValues ? this.formcontrolValues.city : "",
        "offset": this.currentPage == 0 ? 0 : (this.currentPage * 10),
        "limit": 10
    };
    
    if (this.referrelStep == "guru") {
        req.referred_by_guru_region = this.formcontrolValues ? this.formcontrolValues.region : "";
        req.referred_by_guru_phone_number = this.formcontrolValues ? this.formcontrolValues.mobileNo : "";
        req.referred_by_guru_state = this.formcontrolValues ? this.formcontrolValues.state : "";
    } else {
        req.region = this.role !== 'RPM' ? this.formcontrolValues ? this.formcontrolValues.region : "" : "";
        req.guru_phone_number = this.formcontrolValues ? this.formcontrolValues.mobileNo : "";
        req.guru_state = this.formcontrolValues ? this.formcontrolValues.state : "";
    }
      const apiObj = {
        requestObj:req,
        url: url,
        methodType: HttpMethod.POST,
      };
      this.spinner.show();
      this.commonService.commonApiCall(apiObj, (res) => {
        if (
          res &&
          res.hasOwnProperty("result") &&
          res.result &&
          res.result.length
        ) {
          this.spinner.hide();
          if(res.success == true){
            this.showfinalErrorMsg='';
            this.dataSource = new MatTableDataSource(res.result);
            this.dataSource.sort = this.sort;
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = res.count;
            this.childComponent.closeDropdown();
            this.isLoading = false;
            this.showNodata = false;

          }else{
            this.showfinalErrorMsg = res.msg;
            this.showToastermessage = true;
            this.showNodata = true;
          }
        }else {
          this.showNodata = true;
          this.spinner.hide();
          this.showfinalErrorMsg = res.error.msg;
          this.childComponent.closeDropdown();
          this.showToastermessage = true;
        }
      });
    }catch(error){
      this.spinner.hide();
      this.showfinalErrorMsg = error.error.msg;
      this.showToastermessage = true;
      this.showNodata = true;
    }
  }
  async downloadReferrelReportExcel(){
    try{
      this.spinner.show();
      let apiUrl =  this.appService.referrelReport;
      let date = new Date();
      let toDate = this.datePipe.transform(this.formcontrolValues?this.formcontrolValues.toDate:date, "yyyy-MM-dd");
      let fromDate = this.datePipe.transform(this.formcontrolValues?this.formcontrolValues.fromDate:date.setMonth(date.getMonth() - 1), "yyyy-MM-dd");
      let req = {
        "to_date":toDate?toDate:this.datePipe.transform(date,"yyyy-MM-dd"),
        "from_date":fromDate?fromDate:this.datePipe.transform(date.setMonth(date.getMonth() - 3),"yyyy-MM-dd"),
        "referral_report_type": this.referrelStep?this.referrelStep:"prospect",
        "guru_phone_number":"",
        "customer_phone_number":this.formcontrolValues?this.formcontrolValues.custmobileNo:"",
        "prospect_phone_number":this.formcontrolValues?this.formcontrolValues.prospectmobileNo:"",
        "lass_name":this.formcontrolValues?this.formcontrolValues.lassName:"",
        "lass_phone_number":this.formcontrolValues?this.formcontrolValues.lassNumber:"",
        "guru_state":"",
        "region":"",
        "referred_by_guru_state":"",
        "referred_by_guru_region":"",
        "referred_by_guru_phone_number":"",
        "guru_city":this.formcontrolValues?this.formcontrolValues.city:"",
        "sso":this.formcontrolValues?this.formcontrolValues.sso:"",
        "offset": 0,
        "limit": 10
      }
      if(this.referrelStep == "guru"){
        req.referred_by_guru_region=this.formcontrolValues?this.formcontrolValues.region:""
        req.referred_by_guru_phone_number = this.formcontrolValues?this.formcontrolValues.mobileNo:""
        req.referred_by_guru_state = this.formcontrolValues?this.formcontrolValues.state:""
      }else{
        req.region = this.formcontrolValues?this.formcontrolValues.region:""
        req.guru_phone_number = this.formcontrolValues?this.formcontrolValues.mobileNo:""
        req.guru_state = this.formcontrolValues?this.formcontrolValues.state:""
      }
      let totalResult=0;
      let data:any[] = [];
      let res = await this.commonService.postAsyncRequest(apiUrl,req);
      if(res){
        totalResult = res.count;
        data = res.result;
      }
      let rou = Math.floor(totalResult/10);
      rou = (totalResult%10>0) && (rou > 0)?rou+1:rou; 
      if(rou > 1 ){
        for(var i=1;i<rou;i++){
          req.offset = (i * 10);
          res = await this.commonService.postAsyncRequest(apiUrl,req);
          if(res && res.result && res.result.length){
            data = [...data, ...res.result];
          }
        }
      }
      let finalData:ReferrelReport[] = [];
      for (let row of data) {
        const ListInput: ReferrelReport = {} as ReferrelReport;
        ListInput.Id =   row.id;
        if(this.referrelStep == 'prospect'){
          ListInput.Prospect_name = row.prospect_name?row.prospect_name:"NA";
          ListInput.Prospect_created_date = row.created_date?this.datePipe.transform(row.created_date, "dd-MM-yyyy"):"NA";
          ListInput.Prospect_phone_number = row.prospect_phone?row.prospect_phone:"NA";
          ListInput.Location_of_Purchase = row.prospect_state?row.prospect_state:"NA";
          ListInput.Prospect_pincode = row.prospect_pincode?row.prospect_pincode:"NA";
          ListInput.Modal_interested = row.model_interested?row.model_interested:"NA";
          ListInput.Vehicle_final_by = row.vehicle_final_by?row.vehicle_final_by:"NA";
          ListInput.Reminder_date = row.reminder_date?this.datePipe.transform(row.reminder_date):"NA";
        }else if(this.referrelStep == 'prolife engine'){
          ListInput.Customer_name = row.customer_name?row.customer_name:"NA";
          ListInput.Customer_created_date = row.created_date?this.datePipe.transform(row.created_date,"dd-MM-yyyy"):"NA";
          ListInput.Customer_phone_number = row.customer_phone?row.customer_phone:"NA";
          ListInput.Customer_state = row.customer_state?row.customer_state:"NA";
          ListInput.Customer_pincode = row.customer_pincode?row.customer_pincode:"NA";
          ListInput.Modal_interested = row.model_interested?row.model_interested:"NA";
          ListInput.Regulatory_norms = row.regulatory_norms?row.regulatory_norms:"NA";
          ListInput.Customer_registration_number = row.registration_number?row.registration_number:"NA";
          ListInput.Customer_expected_purchase_date = row.expected_purchase_dare?this.datePipe.transform(row.expected_purchase_dare,"dd-MM-yyyy"):"NA";
        }
        if(this.referrelStep == 'prospect' || this.referrelStep == 'prolife engine'){
          ListInput.Mechanic_name = row.guru?row.guru.mechanic_name:'NA';
          ListInput.Garage_area = row.guru?row.guru.garage_area:"NA";
          ListInput.Gstin_number = row.guru? row.guru.gstin_number :'NA' 
          ListInput.Upi_Id = row.guru?row.guru.upi_id:'NA';
          ListInput.Guru_name = row.guru?(row.guru.guru_user_data.first_name +' '+row.guru.guru_user_data.last_name):"NA"; 
          ListInput.Guru_phone_number = row.guru?row.guru.guru_user_data.username :"NA"; 
          ListInput.Guru_state = row.guru?row.guru.state_name :"NA";
          ListInput.Guru_region = row.guru?row.guru.zone :"NA";
          ListInput.Lass_name = row.guru?row.guru.lass_detail.lass_name:'NA';
          ListInput.Lass_number = row.guru?row.guru.lass_detail.lass_mobile_number:'NA';
          ListInput.Lass_email = row.guru?row.guru.lass_detail.lass_email:'NA';
        }else if(this.referrelStep == 'guru'){
          ListInput.Mechanic_name = row.mechanic_name?row.mechanic_name:'NA';
          ListInput.Garage_area = row.garage_area?row.garage_area:"NA";
          ListInput.Source = row.source ?row.source :"NA"; 
          ListInput.Referral_by_id = row.referred_by_id ?row.referred_by_id :"NA"; 
          ListInput.Guru_name = row.referred_by_guru_details ?(row.referred_by_guru_details.referred_by_guru_name):"NA"; 
          ListInput.Guru_phone_number = row.referred_by_guru_details?row.referred_by_guru_details.referred_by_guru_phone_number :"NA"; 
          ListInput.Guru_state = row.referred_by_guru_details?row.referred_by_guru_details.referred_by_guru_state_name :"NA";
          ListInput.Guru_region = row.referred_by_guru_details?row.referred_by_guru_details.referred_by_guru_zone :"NA";
          ListInput.Lass_name = row.lass_detail?row.lass_detail.lass_name:'NA';
          ListInput.Lass_number = row.lass_detail?row.lass_detail.lass_number:'NA';
          ListInput.Lass_email = row.lass_detail?row.lass_detail.lass_email:'NA';
        }
        finalData.push(ListInput);
      }
      if(finalData.length > 0 ){
        this.excelService.exportCancellationAsExcelFile(finalData, this.referrelStep+'_report');
      }else{
        this.showToastermessage = true;
        this.showfinalErrorMsg =
          "No Data For Download";
        setTimeout(() => {
          this.showfinalErrorMsg = "";
          this.showToastermessage = false;
        }, 3000);
      }
    }catch(error){
      this.showToastermessage = true;
      this.showfinalErrorMsg =
        "No Data For Download";
      setTimeout(() => {
        this.showfinalErrorMsg = "";
        this.showToastermessage = false;
      }, 3000);
    }
    this.spinner.hide();
  }
  pageChanged(event) {
    this.pgIndex = event.pageIndex?event.pageIndex:0;
    this.pgIndex = this.pgIndex + 1;
    this.currentPage = event.pageIndex?event.pageIndex:0;
    this.isLoading = true;
    this.getReferrelReportlist(this.formcontrolValues);
  }
  formControlValue(event){
    this.currentPage=0;
    this.formcontrolValues = event;
    this.getReferrelReportlist(event);
  }
  setReferrelType(type:string){
    this.referrelStep = type;
    if(this.formcontrolValues){
      this.formcontrolValues.toDate="";
      this.formcontrolValues.fromDate="";
      this.formcontrolValues.prospectmobileNo="";
      this.formcontrolValues.custmobileNo="";
      this.formcontrolValues.mobileNo="";
    }
    this.pageChanged({pageIndex:0});
  }
}

export interface ReferrelReport{
  Id:string;
  Prospect_name:string;
  Prospect_created_date:string;
  Prospect_phone_number:string;
  Location_of_Purchase:string;
  Prospect_pincode:string;
  Customer_name:string;
  Customer_created_date:string;
  Customer_phone_number:string;
  Customer_state:string;
  Customer_pincode:string;
  Modal_interested:string;
  Regulatory_norms:string;
  Vehicle_final_by:string;
  Reminder_date:string;
  Customer_registration_number:string;
  Customer_expected_purchase_date:string;
  Customer_deleted:string;
  Added_by:string;
  Approved_by_id:string;
  Gstin_number:string;
  Msr_contact:string;
  Upi_Id:string;
  Last_invoice_number:string;
  Guru_created_at:string;
  Guru_updated_at:string;
  Mechanic_name:string;
  Garage_area:string;
  Loyalty_points:string;
  Is_deleted:string;
  Source:string;
  Referral_by_id:string;
  Latitude:string;
  Longitude:string;
  Created_at:string;
  Updated_at:string;
  Guru_id:string;
  Guru_user_id:string;
  Guru_name:string;
  Guru_phone_number:string;
  Guru_state:string;
  Guru_region:string;
  Lass_name:string;
  Lass_number:string;
  Lass_email:string;

}