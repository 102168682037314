import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { StarGuruDetailsDialogComponent } from '../star-guru-details-dialog/star-guru-details-dialog.component';

@Component({
  selector: 'app-edit-guru-type-dialog',
  templateUrl: './edit-guru-type-dialog.component.html',
  styleUrls: ['./edit-guru-type-dialog.component.scss']
})
export class EditGuruTypeDialogComponent implements OnInit {
  selectedGuruType: string;
  @Output() guruTypeSelected = new EventEmitter<string>();
  action_id: any;
  initialGuruType: string; // To store the initial selection
  isNextDisabled: boolean = true; // Controls the "Next" button state
  pincode: any;
  constructor(
    public dialogRef: MatDialogRef<EditGuruTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {
    this.selectedGuruType = data.guru_type;
    this.action_id=data.account_id
    this.pincode=data.pincode
  }

  ngOnInit(): void {
    this.initialGuruType = this.selectedGuruType;
    this.isNextDisabled = true; // Disable the button initially
   
  }

  isSelectionValid: boolean = false;  
  onRadioChange(value: string): void {
    this.selectedGuruType = value;
    this.isSelectionValid = this.selectedGuruType !== this.initialGuruType;
    // this.isSelectionValid = !!value; // Will be true if value exists
  }
  
  openStarGuruDetailsDialog(): void {
    const dialogRef = this.dialog.open(StarGuruDetailsDialogComponent, {
      data: { action_id: this.action_id, guru_type: this.selectedGuruType ,pincode:this.pincode}
      
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('StarGuru details:', result);
        this.guruTypeSelected.emit(this.selectedGuruType);
        this.dialogRef.close(this.selectedGuruType);
      } else {
        // If the StarGuruDetailsDialog was closed without saving,
        // revert the selection to the initial value
        this.selectedGuruType = this.initialGuruType;
      }
    });
  }
  
  onCancel(): void {
    // Restore the initial value when cancel is clicked
    this.selectedGuruType = this.initialGuruType;
    this.dialogRef.close();
    return;
  }
  onSave(): void {
    if (this.selectedGuruType) {
      this.openStarGuruDetailsDialog();
      this.dialogRef.close();
    } else {
      this.guruTypeSelected.emit(this.selectedGuruType);
      this.dialogRef.close();
    }
  }

  closeModal() {
    this.onCancel();
  }
}