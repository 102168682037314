<form [formGroup]="starGuruform">
  <div class="modal-content" style="border: 0.5px solid black; padding: 16px; width: 900px; margin: auto;">
    <div class="modal-header modal-title">
      <strong>Star Guru Details</strong>
      <div (click)="closeModal()" style="cursor: pointer; font-size: 24px;">&times;</div>
    </div>
    <div class="modal-body">
      <div class="row" style="margin-bottom: 16px;">
        <div class="col-6">
          <label for="pincode">Pincode</label>
          <input type="text" id="pincode"minlength="6" maxlength="6" formControlName="pincode" (change)="pincodeChanged($event)" style="width: 100%; padding: 8px; box-sizing: border-box;">

          <div *ngIf="starGuruform.controls.pincode.errors && (starGuruform.controls.pincode.dirty || starGuruform.controls.pincode.touched)" class="alert alert-danger" >
            <div [hidden]="!starGuruform.controls.pincode.errors.required" class="login-error">
                Pincode is required.
            </div>
            <div [hidden]="!(starGuruform.controls.pincode.errors.minlength || starGuruform.controls.pincode.errors.maxlength)" class="login-error">
                Please enter 6 digit pincode.
            </div>
            <div [hidden]="!starGuruform.controls.pincode.errors.pattern" class="login-error">
                only integer allowed
            </div>
        </div>
        </div>
        <div class="col-6">
          <label for="state">State</label>
          <select name="state" formControlName="state" class="form-control" (change)="stateChanged()" required>
            <option value="">Please select State</option>
            <ng-container *ngIf="states">
                <option *ngFor="let item of states; let i = index" [value]="item?.code" >
                    {{item?.name}}
                </option>
            </ng-container>
        </select>
        <div *ngIf="starGuruform.controls.state.errors && (starGuruform.controls.state.dirty || starGuruform.controls.state.touched)" class="alert alert-danger" >
          <div [hidden]="!starGuruform.controls.state.errors.required" class="login-error">
              State is required.
          </div>
      </div>
        </div>
      </div>
      
      <div class="row" style="margin-bottom: 16px;">
        <div class="col-6">
          <label for="district">District</label>
          <select name="district" formControlName="district" class="form-control" (change)="districtChanged()" required>
            <option value="">Please select District</option>
            <ng-container *ngIf="districts">
                <option *ngFor="let item of districts; let i = index" [value]="item?.code" >
                    <ng-container *ngIf="item?.name">
                        {{item?.name}} 
                    </ng-container>
                </option>
            </ng-container>
        </select>
        <div *ngIf="starGuruform.controls.district.errors && (starGuruform.controls.district.dirty || starGuruform.controls.district.touched)" class="alert alert-danger" >
          <div [hidden]="!starGuruform.controls.district.errors.required" class="login-error">
              District is required.
          </div>
      </div>
        </div>
        <div class="col-6">
          <label for="city">City</label>
          <select name="city" formControlName="city" class="form-control" required>
            <option value="">Please select City</option>
            <ng-container *ngIf="cities">
                <option *ngFor="let item of cities; let i = index" [value]="item?.code" >
                    {{item?.name}}
                </option>
            </ng-container>
        </select>
        <div *ngIf="starGuruform.controls.city.errors && (starGuruform.controls.city.dirty || starGuruform.controls.city.touched)" class="alert alert-danger" >
          <div [hidden]="!starGuruform.controls.city.errors.required" class="login-error">
              City is required.
          </div>
      </div>
        </div>
      </div>
      <div class="row" style="margin-bottom: 16px;">
        <div class="col-6">
          <label for="salesedealer">Sales Dealer</label>
          <select name="dealer" formControlName="dealer" class="form-control" (change)=" getsalesDealerDivisions()">
            <option value="">Please select sales dealer</option>
            <ng-container *ngIf="dealers">
                <!-- <option *ngFor="let item of dealers; let x = index"> Options s</option> -->
                <option *ngFor="let item of dealers; let x = index" [value]="item?.code">
                    {{item?.name}}
                </option>
            </ng-container>
        </select>
        <div *ngIf="starGuruform.controls.dealer.errors && (starGuruform.controls.dealer.dirty || starGuruform.controls.dealer.touched)" class="alert alert-danger" >
          <div [hidden]="!starGuruform.controls.dealer.errors.required" class="login-error">
               Sales Dealer is required.
          </div>
      </div>
        </div>
        <div class="col-6">
          <label for="salesdealerdivision">Sales Dealer Division</label>
          <select name="dealer_division" formControlName="dealer_division" class="form-control" (change)="getUserInfo()">
            <option value="">Please select  sales dealer division</option>
            <ng-container *ngIf="dealerDivisions">
                <option *ngFor="let item of dealerDivisions; let i = index" [value]="item?.code" >
                    {{item?.name}}
                </option>
            </ng-container>
        </select>
        <div *ngIf="starGuruform.controls.dealer_division.errors && (starGuruform.controls.dealer_division.dirty || starGuruform.controls.dealer_division.touched)" class="alert alert-danger" >
          <div [hidden]="!starGuruform.controls.dealer_division.errors.required" class="login-error">
               Sales Dealer Division is required.
          </div>
      </div>
        </div>
      </div>
      <div class="row" style="margin-bottom: 16px;">
        <div class="col-6">
          <label for="servicedealer">Service Dealer</label>
          <select name="dealer" formControlName="servicedealer" class="form-control" (change)="getServiceDealerDivisions()">
            <option value="">Please select service dealer</option>
            <ng-container *ngIf="dealers">
                <!-- <option *ngFor="let item of dealers; let x = index"> Options s</option> -->
                <option *ngFor="let item of servicedealers; let x = index" [value]="item?.PAR_BU_ID_s">
                    {{item?.ORG_NAME_s}}
                </option>
            </ng-container>
        </select>
        <div *ngIf="starGuruform.controls.servicedealer.errors && (starGuruform.controls.servicedealer.dirty || starGuruform.controls.servicedealer.touched)" class="alert alert-danger" >
          <div [hidden]="!starGuruform.controls.servicedealer.errors.required" class="login-error">
              Service Dealer is required.
          </div>
      </div>
        </div>
        <div class="col-6">
          <label for="servicedealerdivision">Service Dealer Division</label>
          <select name="dealer_division" formControlName="servicedealer_division" class="form-control" (change)="getServiceUserInfo()">
            <option value="">Please select service dealer division</option>
            <ng-container *ngIf="dealerDivisions">
              <option *ngFor="let item of servicedealerDivisions; let i = index" [value]="item?.ROW_ID">
                {{ item?.DIV_NAME_s }}
              </option>
            </ng-container>
          </select>
          <div *ngIf="starGuruform.controls.servicedealer_division.errors && (starGuruform.controls.servicedealer_division.dirty || starGuruform.controls.servicedealer_division.touched)" class="alert alert-danger">
            <div [hidden]="!starGuruform.controls.servicedealer_division.errors.required" class="login-error">
               Service Dealer Division is required.
            </div>
        </div>
        </div>
      </div>
      
      
    </div>
    <div mat-dialog-actions class="buttons" align="end">
      <button mat-button (click)="onCancel()" class="cancel-button">Cancel</button>
      <button mat-button (click)="onSave()" class="save-button" [disabled]="!disablebtn">Save</button>
    </div>
  </div>
</form>


